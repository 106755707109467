import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
	selector: 'app-float-input',
	templateUrl: './float-input.component.html',
	styleUrl: './float-input.component.css'
})
export class FloatInputComponent {
	@Input() id?: string;
	@Input() control?: FormControl;
	@Input() label?: string;
}
