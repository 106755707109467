import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { retry, throwError, timer } from "rxjs";
import { environment } from "../../../environments/environment";

export const retryInterceptor: HttpInterceptorFn = (req, next) => {
	return next(req)
		.pipe(
			retry({
				count: environment.maxRetries,
				delay: (error: HttpErrorResponse, retryCount: number) => {
					if (error.status >= 500) {
						return timer(1000 * (retryCount * retryCount));
					}

					return throwError(() => error);
				}
			})
		);
};
