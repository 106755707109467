import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Featured } from "../models/featured";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class FeaturedService {

	constructor(private httpClient: HttpClient) {
	}

	public retrieveFeatured = (): Observable<Featured[]> => {
		return this.httpClient.get<Featured[]>(`${environment.apiUrl}/featured`);
	}
}
