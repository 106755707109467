import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { CartProduct } from "../../cart/models/cart-product";
import { CouponResponse } from "../models/coupon-response";
import { StorageService } from "../../services/storage.service";
import { StorageKey } from "../../models/enumeration/storage-key";
import { CouponForm } from "../models/coupon-form";

@Injectable({
	providedIn: 'root'
})
export class CouponService {

	constructor(private httpClient: HttpClient, private storageService: StorageService) {
	}

	public applyCoupon = (coupon: string, cartProducts: CartProduct[]): Observable<CouponResponse> => {
		return this.httpClient.post<CouponResponse>(`${ environment.apiUrl }/coupons/${ coupon }/apply`, cartProducts);
	}

	public saveCoupon = (couponResponse: CouponResponse, couponCode: string): CouponForm => {
		const couponForm: CouponForm = { couponResponse: couponResponse, couponCode: couponCode };
		this.storageService.store(StorageKey.COUPON, couponForm);
		return couponForm;
	}

	public getAppliedCoupon = (): Observable<CouponForm | undefined> => {
		return of(
			this.storageService.get(StorageKey.COUPON) as CouponForm | undefined
		);
	}

	public removeCoupon = (): Observable<void> => {
		this.storageService.remove(StorageKey.COUPON);
		return of();
	}
}
