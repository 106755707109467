<p-sidebar position="right" [(visible)]="isSidebarVisible">
	<ng-template pTemplate="headless">
		<div class="sections-wrapper">
			<div class="product-section">
				<h3 class="sidebar-title">Shopping cart</h3>

				<div class="products-wrapper">
					@for (cartProduct of cartProducts$?.(); track cartProduct.product.id; let index = $index) {
						<div class="product">
							@if (cartProduct.product.images && cartProduct.product.images[0].imageUrl) {
								<div class="image-wrapper">
									<img [src]="cartProduct.product.images[0].imageUrl" alt="">
								</div>
							}
							<div class="details">
								<span class="product-name">{{ cartProduct.product.name }}</span>
								<div class="quantity">
									<div class="updown-wrapper">
										<app-updown [(value)]="cartProduct.quantity"
													(increase)="increaseQuantity(cartProduct)"
													(decrease)="decreaseQuantity(cartProduct)"
													[maximum]="cartProduct.product.stock"/>
									</div>

									<div class="costs">
													<span class="list-price"
														  [ngClass]="{'discounted': cartProduct.product.salePrice}">
														{{ cartProduct.product.listPrice * cartProduct.quantity | currency:'EUR' }}
													</span>
										@if (cartProduct.product.salePrice) {
											<span class="sale-price">{{ cartProduct.product.salePrice * cartProduct.quantity | currency:'EUR' }}</span>
										}
									</div>
								</div>
							</div>
						</div>

						@if (index < (cartProducts$?.()?.length ?? 0) - 1) {
							<app-header-divider lineHeight="1px" color="rgba(255, 255, 255, .4)"/>
						}
					} @empty {
						<div class="empty-cart-container">
							<div class="empty-cart-wrapper">
								<img class="empty-cart" src="/assets/images/empty-cart.svg"
									 alt="Carrello vuoto">
							</div>

							<span class="cart-label">Nessun prodotto nel carrello.</span>

							<div class="button-wrapper">
								<app-outline-button (clickEvent)="closeSidebarAndNavigate('/')" styleClass="return-button">
									RETURN TO SHOP
								</app-outline-button>
							</div>
						</div>
					}
				</div>
			</div>
			<div class="footer">
				<div class="subtotal">
					<span class="label">Subtotale:</span>
					<span class="value">{{ cartCosts$?.()?.subtotal ?? 0 | currency:'EUR' }}</span>
				</div>

				<div class="buttons">
					<app-outline-button (clickEvent)="closeSidebarAndNavigate('/cart')">
						VISUALIZZA CARRELLO
					</app-outline-button>
					<app-outline-button (clickEvent)="closeSidebarAndNavigate('/checkout')">
						PAGAMENTO
					</app-outline-button>
				</div>
			</div>
		</div>
	</ng-template>
</p-sidebar>