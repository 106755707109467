<div class="container">
	<div class="input-wrapper">
		<p-floatLabel>
			<input id="coupon-input" pInputText [(ngModel)]="couponForm?.couponCode ?? couponCode"/>
			<label class="input-label" for="coupon-input">Inserisci codice</label>
		</p-floatLabel>

		<p-button (click)="handleCoupon()">{{ couponForm ? 'RIMUOVI' : 'APPLICA' }}</p-button>
	</div>

	@if (errorMessage) {
		<div class="error-wrapper">
			<span class="error-message">{{ errorMessage }}</span>
		</div>
	}
</div>