import { AbstractControl } from "@angular/forms";

export function onlyNumbers(control: AbstractControl) {
	const controlValue = Number.parseInt(control.value);

	if (!isNaN(controlValue) || control.value == null) {
		return null;
	}

	return {
		onlyNumbers: false
	}
}