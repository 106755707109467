import { Component, Input } from '@angular/core';
import { Product } from "../../models/product";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: 'app-notify-me',
	templateUrl: './notify-me.component.html',
	styleUrl: './notify-me.component.css'
})
export class NotifyMeComponent {
	@Input() product?: Product;

	public formGroup: FormGroup;
	public isInvalid?: boolean;

	constructor() {
		this.formGroup = new FormGroup({
			email: new FormControl('', [Validators.required, Validators.email])
		});
	}

	public notifyMe = () => {
		if (this.formGroup.invalid) {
			this.isInvalid = true;
			return;
		}

		this.isInvalid = false;

		// TODO: BE request to insert a notify event
	}
}
