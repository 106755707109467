import { Component, OnDestroy, OnInit } from '@angular/core';
import { Step } from "../../../shared-components/models/step";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CartProduct } from "../../models/cart-product";
import { CartService } from "../../../services/cart.service";
import { Country } from "../../models/country";
import { onlyNumbers } from "../../../shared-components/forms-utils/validators";
import { CountryService } from "../../service/country.service";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrl: './checkout.component.css'
})
export class CheckoutComponent implements OnInit, OnDestroy {
	public steps: Step[];
	public formGroup?: FormGroup;
	public countries?: Country[];
	public cartProducts: CartProduct[];

	private countrySubscription?: Subscription;

	constructor(private cartService: CartService, private countryService: CountryService) {
		this.steps = [
			{ name: 'shopping cart', link: '/cart', active: false },
			{ name: 'checkout', link: '/checkout', active: true },
			{ name: 'order complete', active: false },
		];

		this.cartProducts = [];
	}

	ngOnInit() {
		this.cartProducts = this.cartService.getSavedProducts();

		this.countrySubscription = this.countryService.getAllCountries()
			.subscribe({
				next: countries => {
					this.countries = countries;
					const defaultCountry = countries.find(country => country.default);
					const defaultProvince = countries.flatMap(country => country.provinces).find(province => province?.default);

					this.formGroup = new FormGroup({
						email: new FormControl('', [Validators.required, Validators.email]),
						firstName: new FormControl('', [Validators.required]),
						lastName: new FormControl('', [Validators.required]),
						address: new FormControl('', [Validators.required]),
						apartment: new FormControl('', [Validators.required]),
						country: new FormControl(defaultCountry ?? countries[0], [Validators.required]),
						zipCode: new FormControl('', [Validators.required, onlyNumbers]),
						city: new FormControl('', [Validators.required]),
						province: new FormControl(defaultProvince ?? null, [Validators.required]),
						phone: new FormControl(null, [onlyNumbers]),
						billingFirstName: new FormControl('', [Validators.required]),
						billingLastName: new FormControl('', [Validators.required]),
						billingAddress: new FormControl('', [Validators.required]),
						billingApartment: new FormControl('', [Validators.required]),
						billingCountry: new FormControl(defaultCountry, [Validators.required]),
						billingZipCode: new FormControl('', [Validators.required, onlyNumbers]),
						billingCity: new FormControl('', [Validators.required]),
						billingProvince: new FormControl(defaultProvince ?? null, [Validators.required]),
						billingPhone: new FormControl(null, [onlyNumbers]),
						cardNumber: new FormControl(null),
						expirationDate: new FormControl(null),
						securityCode: new FormControl(null),
						note: new FormControl(''),
					});
				}
			});
	}

	ngOnDestroy() {
		this.countrySubscription?.unsubscribe();
	}

}
