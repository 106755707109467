import { Injectable } from '@angular/core';
import { Observable, of, Subject } from "rxjs";
import { StorageService } from "./storage.service";
import { StorageKey } from "../models/enumeration/storage-key";
import { Product } from "../shared-components/models/product";
import { CartProduct } from "../cart/models/cart-product";
import { ActionType } from "../models/enumeration/action-type";
import { CouponForm } from "../shared-components/models/coupon-form";
import { CartCosts } from "../models/cart-costs";
import { Shipping } from "../cart/models/shipping";

@Injectable({
	providedIn: 'root'
})
export class CartService {
	private _cart: Subject<void>;
	private _toggleCart: Subject<void>;
	private shipping?: Shipping;

	constructor(private storageService: StorageService) {
		this._cart = new Subject<void>();
		this._toggleCart = new Subject<void>();
	}

	public getDataChanges = (): Observable<void> => {
		return this._cart.asObservable();
	}

	public storeProduct = (product: Product, quantity?: number) => {
		this.storeCartProduct({ product: product, quantity: quantity ?? 1 });
	}

	public storeCartProduct = (cartProduct: CartProduct) => {
		const actualProducts: CartProduct[] = this.getSavedProducts();
		const existingProduct = this.retrieveExistingProduct(cartProduct.product.id);
		const products: CartProduct[] = existingProduct
			? this.addQuantityToProduct(existingProduct, cartProduct.quantity)
			: [...actualProducts, cartProduct];

		this.storageService.store(StorageKey.CART_PRODUCTS, products);
		this.notify();
	}

	public removeProduct = (product: Product) => {
		const updatedProducts: CartProduct[] = this._removeProduct(product.id);

		this.storageService.store(StorageKey.CART_PRODUCTS, updatedProducts);
		this.notify();
	}

	public getSavedProducts = (): CartProduct[] => {
		return this.storageService.get(StorageKey.CART_PRODUCTS) ?? [];
	}

	public getSavedProductsObs = (): Observable<CartProduct[]> => {
		return of(this.storageService.get(StorageKey.CART_PRODUCTS) ?? []);
	}

	public editProductQuantity = (cartProduct: CartProduct, action: ActionType) => {
		const item: CartProduct | undefined = this.retrieveExistingProduct(cartProduct.product.id);
		const updatedProducts: CartProduct[] = this._removeProduct(cartProduct.product.id);

		if (item) {
			action === ActionType.INCREASE ? item.quantity++ : item.quantity--;
			updatedProducts.push(item);

			this.storageService.store(StorageKey.CART_PRODUCTS, updatedProducts);
			this.notify();
		}
	}

	public getCartCost = (): Observable<CartCosts> => {
		const subtotal = this.getSavedProducts()
			.reduce((previousValue, currentValue) => (previousValue + (currentValue.quantity * (currentValue.product.salePrice ?? currentValue.product.listPrice))), 0);
		const coupon = this.storageService.get(StorageKey.COUPON) as CouponForm | undefined;
		const shippingCost: number | undefined = this.shipping && subtotal > this.shipping?.freeAbove ? 0 : this.shipping?.cost;
		const total = subtotal - (coupon?.couponResponse.discountedAmount ?? 0) + (shippingCost ?? 0);
		const shippingCompany = this.shipping?.company ?? '';

		return of({ couponForm: coupon, subtotal: subtotal, shippingCost: shippingCost, shippingCompany: shippingCompany, total: total });
	}

	public cartToggled = (): Observable<void> => {
		return this._toggleCart.asObservable();
	}

	public toggleCart = (): void => {
		this._toggleCart.next();
	}

	private addQuantityToProduct = (cartProduct: CartProduct, quantity: number): CartProduct[] => {
		const updatedProducts = this._removeProduct(cartProduct.product.id);
		cartProduct.quantity = cartProduct.quantity + quantity;

		return [...updatedProducts, cartProduct];
	}

	private _removeProduct = (productId: number): CartProduct[] => {
		const actualProducts: CartProduct[] = this.getSavedProducts();
		actualProducts.splice(actualProducts.findIndex(cartProduct => cartProduct.product.id === productId), 1);
		return actualProducts;
	}

	private notify = () => {
		this._cart.next();
	}

	private retrieveExistingProduct = (productId: number): CartProduct | undefined => {
		return this.getSavedProducts().find(cartProduct => cartProduct.product.id === productId);
	}

	public setShipping = (shipping: Shipping): void => {
		this.shipping = shipping;
	}
}
