import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { ButtonModule } from "primeng/button";
import { BadgeModule } from "primeng/badge";
import { CartButtonComponent } from './components/cart-button/cart-button.component';
import { StockLabelComponent } from './components/stock-label/stock-label.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TagModule } from "primeng/tag";
import { SharedModule } from "primeng/api";
import { WishlistButtonComponent } from './components/wishlist-button/wishlist-button.component'
import { TooltipModule } from "primeng/tooltip";
import { ProductCardComponent } from "./components/product-card/product-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { FloatLabelModule } from "primeng/floatlabel";
import { LoadingComponent } from './views/loading/loading.component';
import { PageStepperComponent } from './components/page-stepper/page-stepper.component';
import { InputNumberModule } from "primeng/inputnumber";
import { UpdownComponent } from './components/updown/updown.component';
import { CartMenuComponent } from './components/cart-menu/cart-menu.component';
import { HeaderDividerComponent } from "./components/header-divider/header-divider.component";
import { InputIconModule } from "primeng/inputicon";
import { IconFieldModule } from "primeng/iconfield";
import { InputTextModule } from "primeng/inputtext";
import { FloatInputComponent } from './components/float-input/float-input.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { FormErrorDirective } from './directives/form-error.directive';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { CheckboxModule } from "primeng/checkbox";
import { FormAddressSectionComponent } from './components/form-address-section/form-address-section.component';
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { TabViewModule } from "primeng/tabview";
import { BuyButtonComponent } from './components/buy-button/buy-button.component';
import { AddToCardButtonComponent } from './components/add-to-card-button/add-to-card-button.component';
import { NotifyMeComponent } from './components/notify-me/notify-me.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { InplaceModule } from "primeng/inplace";
import { InputTextareaModule } from "primeng/inputtextarea";
import { SidebarModule } from "primeng/sidebar";
import { OutlineButtonComponent } from './components/outline-button/outline-button.component';
import { CartSidebarComponent } from './components/cart-sidebar/cart-sidebar.component';
import { ClickableSelectComponent } from './components/clickable-select/clickable-select.component';
import { SliderModule } from "primeng/slider";
import { PriceFilterComponent } from './components/price-filter/price-filter.component';


@NgModule({
	declarations: [
		ButtonIconComponent,
		CartButtonComponent,
		StockLabelComponent,
		WishlistButtonComponent,
		ProductCardComponent,
		LoadingComponent,
		PageStepperComponent,
		UpdownComponent,
		CartMenuComponent,
		HeaderDividerComponent,
		FloatInputComponent,
		FormErrorMessageComponent,
		FormErrorDirective,
		FormFieldComponent,
		FormAddressSectionComponent,
		SearchBoxComponent,
		BuyButtonComponent,
		AddToCardButtonComponent,
		NotifyMeComponent,
		CouponComponent,
		OutlineButtonComponent,
		CartSidebarComponent,
  ClickableSelectComponent,
  PriceFilterComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
		BadgeModule,
		BrowserAnimationsModule,
		TagModule,
		SharedModule,
		TooltipModule,
		RouterModule,
		FormsModule,
		FloatLabelModule,
		ReactiveFormsModule,
		InputTextModule,
		DropdownModule,
		IconFieldModule,
		InputIconModule,
		SidebarModule,
		SliderModule
	],
	exports: [
		ButtonIconComponent,
		CartButtonComponent,
		StockLabelComponent,
		BrowserAnimationsModule,
		RouterModule,
		ButtonModule,
		TagModule,
		SharedModule,
		WishlistButtonComponent,
		ProductCardComponent,
		ReactiveFormsModule,
		DropdownModule,
		FloatLabelModule,
		LoadingComponent,
		PageStepperComponent,
		InputNumberModule,
		FormsModule,
		UpdownComponent,
		HeaderDividerComponent,
		InputIconModule,
		IconFieldModule,
		InputTextModule,
		FloatInputComponent,
		FormErrorMessageComponent,
		FormErrorDirective,
		FormFieldComponent,
		CheckboxModule,
		FormAddressSectionComponent,
		AccordionModule,
		AutoCompleteModule,
		SearchBoxComponent,
		TabViewModule,
		BuyButtonComponent,
		NotifyMeComponent,
		InplaceModule,
		CouponComponent,
		InputTextareaModule,
		SidebarModule,
		OutlineButtonComponent,
		CartSidebarComponent,
		ClickableSelectComponent,
		PriceFilterComponent
	]
})
export class SharedComponentsModule {
}
