import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { PaginatorState } from "primeng/paginator";
import { Product } from '../../../shared-components/models/product';
import { mergeMap, Observable, Subscription } from 'rxjs';
import { Page } from '../../../shared-components/models/page';
import { ProductService } from "../../services/product.service";
import { getTitle } from "../../../shared-components/utils/title-utils";
import { HttpParams } from "@angular/common/http";

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrl: './products.component.css'
})
export class ProductsComponent implements OnInit, OnDestroy {
	public products?: Page<Product>;
	public isMobile: boolean = false;
	public rows: number;
	public first: number;
	public options: { label: number, value: number }[];
	public subcategoryUrl: string;
	public showFilters: boolean;
	public currentAvailabilityFilter: string;
	public priceRange?: number[];

	private productSearchText: string;
	private currentPage: number;
	private productsSubscription?: Subscription;
	private paramsSubscription?: Subscription;
	private filters: HttpParams;

	constructor(
		private route: ActivatedRoute,
		private productService: ProductService,
		private titleService: Title,
		private breakpointObserver: BreakpointObserver
	) {
		this.first = 0;
		this.rows = 20;
		this.options = [
			{ label: 20, value: 20 },
			{ label: 30, value: 30 },
			{ label: 40, value: 40 },
			{ label: 50, value: 50 }
		];
		this.subcategoryUrl = "";
		this.productSearchText = "";
		this.currentPage = 0;
		this.showFilters = false;
		this.currentAvailabilityFilter = 'Tutte';
		this.filters = new HttpParams();
	}

	ngOnInit() {
		this.paramsSubscription = this.route.url
			.pipe(
				mergeMap(url => url.find(urlSegment => urlSegment.path === 'search') !== undefined ? this.route.queryParams : this.route.params),
				mergeMap(params => {
					this.subcategoryUrl = params['category_last_subpath'];
					this.productSearchText = params['product'];

					return this.getProducts(0, this.rows);
				})
			)
			.subscribe({
				next: (productPage: Page<Product>) => {
					this.products = productPage;

					if (this.subcategoryUrl) {
						this.titleService.setTitle(getTitle(productPage && productPage.content && productPage.content[0]?.category.name));
					} else {
						this.titleService.setTitle(`Hai cercato ${ this.productSearchText } - Caveaugames`);
					}

					const productPrices: number[] = productPage.content.map(product => product.salePrice ? product.salePrice : product.listPrice);
					this.priceRange = [Math.min(...productPrices), Math.max(...productPrices)];
				}
			});

		this.breakpointObserver.observe([Breakpoints.XSmall])
			.subscribe({
				next: result => this.isMobile = result.matches
			});
	}

	ngOnDestroy() {
		this.productsSubscription?.unsubscribe();
		this.paramsSubscription?.unsubscribe();
	}

	public onPageChange = (event: PaginatorState) => {
		this.currentPage = event.page ?? 0;
		this.getProducts(event.page, event.rows)
			.subscribe({ next: products => this.products = products });
	}

	public pageSizeChanged = (e: number) => {
		if (e !== this.rows) {
			this.getProducts(this.currentPage, e)
				.subscribe({ next: products => this.products = products });
		}
	}

	private getProducts = (page: number = 0, size: number = 20): Observable<Page<Product>> => {
		return this.subcategoryUrl
			? this.productService.getProductsByCategoryUrl(this.subcategoryUrl, page, size, this.filters)
			: this.productService.searchProducts(this.productSearchText, page, size, this.filters);
	}

	private retrieveProducts = () => {
		this.getProducts(0, this.rows)
			.subscribe({
				next: productPage => {
					this.products = productPage;
					this.currentPage = 0;
				}
			});
	}

	public handleFilters = (): void => {
		this.showFilters = !this.showFilters;
	}

	public filterBy = (filter: string): void => {
		this.filters = this.filters
			.set('availability', filter);

		if (this.currentAvailabilityFilter !== filter) {
			this.retrieveProducts();
		}

		this.currentAvailabilityFilter = filter;
		this.handleFilters();
	}

	public filterByPrice = (): void => {
		if (this.priceRange) {
			this.filters = this.filters
				.set('minPrice', this.priceRange[0])
				.set('maxPrice', this.priceRange[1]);

			this.retrieveProducts();
		}

		this.handleFilters();
	}

	public orderBy = (event: Event): void => {
		const order: string = (event.target as HTMLSelectElement).value;

		if (order) {
			this.filters = this.filters
				.set('order', order);
			this.retrieveProducts();
		}
	}
}
