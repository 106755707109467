import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrl: './mobile-sidenav.component.css'
})
export class MobileSidenavComponent {

}
