@if (product) {
	<div class="general-info">
		<div class="tabs">
			<p-tabView>
				@if (product.description) {
					<p-tabPanel header="DESCRIZIONE">
						<span class="description" [innerHTML]="product.description"></span>
					</p-tabPanel>
				}
				@if (true) {
					<p-tabPanel header="CONDIZIONE DELLA CARTA">
						<p-accordion multiple>
							<p-accordionTab header="Near Mint (NM)">
								Una carta Near Mint ha l’aspetto di non essere mai stata giocata senza bustine. Ci
								possono essere piccole tolleranze, ma la carta generalmente non ha segni.
								<br>
								<br>
								Il bordo di una carta NM può avere piccoli puntini bianchi, ma devono essere molto
								pochi e piccoli. Quando la carta è analizzata alla luce diurna, la superficie deve
								essere pulita.
							</p-accordionTab>
							<p-accordionTab header="Excellent (EX)">
								Una carta Excellent appare come se fosse stata usata per alcune partite senza
								bustine. una carta Excellent appare quasi sempre in condizioni non perfette dalla
								prima ispezione. Comunque, anche se il danno è chiaramente visibile, è solamente di
								minore entità.
								<br>
								<br>
								Le carte Excellent normalmente hanno un paio di macchiette bianche agli angoli o
								intorno ai bordi. la superficie può avere dei piccoli graffietti, che sono visibili
								con un’ispezione più ravvicinata. Comunque, la carta non può essere valutata come
								Excellent se i graffi sono così profondida essere visibili al primo sguardo.
							</p-accordionTab>
							<p-accordionTab header="Good (GD)">
								Una carta Good appare come se fosse stata utilizzata per un lungo torneo senza
								bustina protettiva.
								<br>
								<br>
								Le carte in condizioni Good usualmente hanno segni molto evidenti su tutta la carta.
								I bordi e gli angoli hanno alcune macchie bianche, la superficie ha graffi e ha
								accumulato della sporcizia. Comunque, i danni di questa carta derivano solamente da
								un regolare uso a scopo di gioco.
							</p-accordionTab>
							<p-accordionTab header="Played (PL)">
								Una carta Played appare in uno stato tanto cattivo quanto è possibile che diventi
								una carta con un uso regolare senza bustine protettive.
								<br>
								<br>
								La carta non è stata manomessa in nessun altro modo (bordi inchiostrati, scarabocchi
								casuali sulla carta etc.).
							</p-accordionTab>
							<p-accordionTab header="Poor (PO)">
								Una carta Poor ha dei danni che non possono essere derivati dall’uso regolare della
								carta.
								<br>
								<br>
								Una carta in condizioni Poor è letteralmente distrutta. È stata manomessa in modo
								tale da distruggere il suo valore quasi completamente (bordi inchiostrati,
								scarabochi casuali etc.).
							</p-accordionTab>
							<p-accordionTab header="Inked (IK)">
								Una carta è considerata inked quando ci sono segni di inchiostro (Esempio Penna o
								Pennarello). La condizione di una carta Inked può essere anche in ottimo stato.
							</p-accordionTab>
						</p-accordion>
					</p-tabPanel>
				}
				<p-tabPanel header="SPEDIZIONE">
					<p-accordion multiple>
						<p-accordionTab header="SPEDIZIONI IN ITALIA: GRATIS PER ORDINI SUPERIORI A 150€">
							Le spese di spedizione con corriere espresso sono gratuite per ordini con importo pari o
							superiore a €150,00 iva compresa. Per ordini di importo inferiore a €150,00 iva compresa
							è possibile scegliere tra due tipi di spedizioni:
							<br>
							<br>
							<b>Corriere Espresso: €8,00 iva compresa</b>
							<br>
							<br>
							Al momento gli ordini vengono spediti in <b>1/2 giorni lavorativi</b>. Sono escluse le
							consegne
							di sabato, domenica e giorni festivi.
						</p-accordionTab>
						<p-accordionTab header="SPEDIZIONI ALL'ESTERO">
							Attualmente spediamo in tutta Europa, con tre modalità di spedizione:
							<br>
							<br>
							Poste Delivery int. Standard: da €18,00 a €60,00 iva compresa(in base al peso e alla
							grandezza del pacco)
						</p-accordionTab>
						<p-accordionTab header="COSA SUCCEDE SE IL CORRIERE NON RIESCE A CONSEGNARE IL TUO PACCO">
							Se il corriere non ti ha trovato a casa effettuerà un nuovo tentativo di consegna il
							giorno lavorativo successivo. (solo nel caso in cui la spedizione sia stata fatta con
							corriere espresso). Se il corriere non dovesse riuscire a consegnare il tuo pacco per
							cause di forza maggiori (indirizzo inesistente o destinatario assente dopo più
							tentativi) riceverai una mail da SDA per avvisarti della giacenza del tuo pacco e
							chiedendoti disposizioni in merito. Qualora non ricevessi la mail, non preoccuparti: ti
							contatterà telefonicamente la nostra assistenza clienti per concordare con te un nuovo
							tentativo di consegna.
						</p-accordionTab>
						<p-accordionTab header="CAMBIARE INDIRIZZO DI CONSEGNA">
							Puoi cambiare l’indirizzo di consegna solo se l’ordine è ancora in attesa di pagamento o
							se non è ancora stato elaborato contattando l’assistenza tramite Mail o Whatsapp.
							Purtroppo per motivi logistici non sarà possibile modificare l’indirizzo una volta che
							l’ordine è stato elaborato o consegnato al corriere. Dunque prima di trasmettere
							l’ordine assicurati che sia compilato correttamente e sia proprio quello dove vuoi farti
							arrivare il pacco!
						</p-accordionTab>
					</p-accordion>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>
}