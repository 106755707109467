import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { CartService } from "../../../services/cart.service";
import { CartProduct } from "../../../cart/models/cart-product";

@Component({
	selector: 'app-cart-button',
	templateUrl: './cart-button.component.html',
	styleUrl: './cart-button.component.css'
})
export class CartButtonComponent implements OnInit {
	public quantity: number;
	public cost: number;

	constructor(
		public deviceService: DeviceDetectorService,
		private cartService: CartService
	) {
		this.quantity = 0;
		this.cost = 0.0;

		this.initializeCart();
	}

	ngOnInit(): void {
		this.cartService.getDataChanges()
			.subscribe({
				next: _ => {
					this.initializeCart();
				}
			});
	}

	public initializeCart = () => {
		const cartProducts = this.cartService.getSavedProducts();
		this.quantity = this.getProductQuantity(cartProducts);
		this.cartService.getCartCost()
			.subscribe({
				next: cartCosts => this.cost = cartCosts.subtotal
			});
	}

	private getProductQuantity = (cartProducts: CartProduct[]) => {
		return cartProducts.map(cartProduct => cartProduct.quantity)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
	}

	public openSidenav = (): void => {
		this.cartService.toggleCart();
	}
}
