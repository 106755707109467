import { Component, OnInit } from '@angular/core';
import { FeaturedProduct } from "../../models/featured-product";
import { FeaturedProductService } from "../../services/featured-product.service";

@Component({
	selector: 'app-products-sections',
	templateUrl: './products-sections.component.html',
	styleUrl: './products-sections.component.css'
})
export class ProductsSectionsComponent implements OnInit {
	public featuredProducts?: FeaturedProduct[];

	constructor(private featuredProductService: FeaturedProductService) {
	}

	ngOnInit() {
		this.featuredProductService.retrieveFeaturedProducts()
			.subscribe({ next: featuredProduct => this.featuredProducts = featuredProduct });
	}
}
