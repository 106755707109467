import { Component, inject, Injector, OnInit, Signal } from '@angular/core';
import { CartProduct } from "../../../cart/models/cart-product";
import { ActionType } from "../../../models/enumeration/action-type";
import { toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { CartService } from "../../../services/cart.service";
import { CartCosts } from "../../../models/cart-costs";

@Component({
	selector: 'app-cart-sidebar',
	templateUrl: './cart-sidebar.component.html',
	styleUrl: './cart-sidebar.component.css'
})
export class CartSidebarComponent implements OnInit {
	public cartProducts$?: Signal<CartProduct[]>;
	public isSidebarVisible: boolean;
	public cartCosts$?: Signal<CartCosts>;

	private readonly injector: Injector;

	constructor(
		private cartService: CartService,
		private router: Router
	) {
		this.isSidebarVisible = false;
		this.injector = inject(Injector);
	}

	ngOnInit() {
		this.loadProducts();

		this.cartService.getDataChanges()
			.subscribe({
				next: _ => this.loadProducts()
			});

		this.cartService.cartToggled()
			.subscribe({
				next: _ => this.isSidebarVisible = !this.isSidebarVisible
			});
	}

	public increaseQuantity = (cartProduct: CartProduct) => {
		this.cartService.editProductQuantity(cartProduct, ActionType.INCREASE);
	}

	public decreaseQuantity = (cartProduct: CartProduct): void => {
		if (cartProduct.quantity === 0) {
			this.cartService.removeProduct(cartProduct.product);
		} else {
			this.cartService.editProductQuantity(cartProduct, ActionType.DECREASE);
		}
	}

	private loadProducts = () => {
		this.cartCosts$ = toSignal(this.cartService.getCartCost(), { requireSync: true, injector: this.injector });
		this.cartProducts$ = toSignal(this.cartService.getSavedProductsObs(), {
			requireSync: true,
			injector: this.injector
		});
	}

	public closeSidebarAndNavigate = (url: string): void => {
		this.router.navigate([url]);
		this.isSidebarVisible = false;
	}
}
