<div class="container">
    <div class="stepper-container">
        <app-page-stepper [steps]="steps" />
    </div>

    <div class="content-wrapper">
        <div class="products-wrapper">
            <div class="header">
                <h4 class="header-item">PRODOTTO</h4>
                <h4 class="header-item">TOTALE</h4>
            </div>
            <app-header-divider class="products-divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>
            <div class="products">
                <app-cart-products/>
            </div>
        </div>
        <div class="details">
            <app-cart-details/>
        </div>
    </div>
</div>