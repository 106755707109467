<div class="wrapper">
    <div class="top-header">
        @if (!deviceService.isDesktop()) {
            <div class="sidenav">
                <i class="pi pi-bars"></i>
                <span>MENU</span>
            </div>
        }

        <img class="logo" src="/assets/images/logo_animato-1-1.gif" alt="Logo" routerLink="/">

        @if (deviceService.isDesktop()) {
            <div class="icon-field-wrapper">
                <app-search-box/>
            </div>
            <app-button-icon icon="pi-user"/>
            <app-wishlist-menu/>
        }

        <app-cart-button/>
    </div>
</div>