import { Component, OnInit } from '@angular/core';
import { SocialAccounts } from "../../models/social-accounts";

@Component({
	selector: 'app-general-section',
	templateUrl: './general-section.component.html',
	styleUrl: './general-section.component.css'
})
export class GeneralSectionComponent implements OnInit {
	public socialAccounts?: SocialAccounts;

	ngOnInit() {
		this.socialAccounts = {
			facebookUrl: 'https://www.facebook.com/groups/318780712003203/user/100071476754754/',
			instagramUrl: 'https://www.instagram.com/caveaugames',
			tiktokUrl: 'https://www.tiktok.com/@caveaugames',
			whatsappUrl: 'https://wa.me/393534625918'
		}
	}

}
