@if (product) {
    <div class="border-1 surface-border border-round m-2 p-3 shadow-4 card-container">
        <div class="product-card">
            <div class="product-content">
                <div class="product-buttons product-content-item">
                    <p-button class="product-button" icon="pi pi-search" rounded="true" severity="secondary"
                              outlined="true"
                              pTooltip="Quick view" tooltipPosition="top"/>
                    <app-wishlist-button [productId]="product.id"/>
                </div>
                <div class="mb-3 product-content-item product-image-wrapper">
                    <div class="relative mx-auto">
                        <img class="product-image" [src]="product.images[0].imageUrl" [alt]="product.name"/>
                        @if (product.salePrice) {
                            <p-tag [value]="getSalePercentage(product)" class="absolute"
                                   [ngStyle]="{ 'left.px': 5, 'top.px': 5 }"/>
                        }
                    </div>
                </div>
                <div class="product-content-item"></div>
            </div>
            <a class="mb-2 font-medium product-name product-name">
                {{ product.name }}
            </a>
            <app-stock-label class="mb-3 font-bold" [productQuantity]="product.stock"></app-stock-label>
        </div>
        <div class="flex justify-content-between align-items-center">
            <div class="price">
                <p class="m-0 list-price"
                   [ngStyle]="{'text-decoration': product.salePrice ? 'line-through' : 'none', 'font-weight': product.salePrice ? 220 : 'regular'}">{{ product.listPrice | currency:'EUR' }}</p>
                @if (product.salePrice) {
                    <span class="sale-price font-bold">{{ product.salePrice | currency:'EUR' }}</span>
                }
            </div>
            <span>
                <p-button icon="pi pi-shopping-cart" styleClass="ml-2 cart-button" (click)="addToCart(product)"/>
            </span>
        </div>
    </div>
}