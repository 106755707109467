import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
	selector: 'app-form-field',
	templateUrl: './form-field.component.html',
	styleUrl: './form-field.component.css'
})
export class FormFieldComponent {
	@Input() control!: FormControl;
	@Input() id!: string;
	@Input() label!: string;
	@Input() errorClass!: string;
	@Input() errorMessage!: string;
}
