import { Component, Input } from '@angular/core';
import { Product } from "../../../shared-components/models/product";

@Component({
	selector: 'app-product-tabs',
	templateUrl: './product-tabs.component.html',
	styleUrl: './product-tabs.component.css'
})
export class ProductTabsComponent {
	@Input() product?: Product;
}
