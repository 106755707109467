import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from "./header/header.module";
import { FooterModule } from "./footer/footer.module";
import { CategoryModule } from "./category/category.module";
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { SharedModule } from "primeng/api";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { HomepageModule } from "./homepage/homepage.module";
import { retryInterceptor } from "./services/interceptors/retry.interceptor";
import { ProductModule } from "./product/product.module";
import { SidebarModule } from "primeng/sidebar";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HeaderModule,
		FooterModule,
		CategoryModule,
		HttpClientModule,
		SharedModule,
		SharedComponentsModule,
		HomepageModule,
		ProductModule,
		SidebarModule
	],
	bootstrap: [AppComponent],
	providers: [
		provideHttpClient(withInterceptors([retryInterceptor])),
	]
})
export class AppModule {
}