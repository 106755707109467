import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
	public isMobile: boolean = false;

	constructor(private breakpointObserver: BreakpointObserver) {
	}

	ngOnInit() {
		this.breakpointObserver.observe([Breakpoints.XSmall])
			.subscribe({ next: result => this.isMobile = result.matches });
	}
}
