import { Component, OnInit } from '@angular/core';
import { FooterSection } from "../../models/footer-section";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit {
	public sections?: FooterSection[];

	constructor(public deviceService: DeviceDetectorService) {
	}

	ngOnInit() {
		this.sections = [
			{
				sectionName: 'informazioni',
				sectionLinks: [
					{
						name: 'Chi siamo',
						url: '#'
					},
					{
						name: 'Vendici le tue Carte',
						url: '#'
					},
					{
						name: 'Prevendite',
						url: '#'
					},
					{
						name: 'Codici Coupon',
						url: '#'
					},
				]
			},
			{
				sectionName: 'assistenza',
				sectionLinks: [
					{
						name: 'Contatti',
						url: '#'
					},
					{
						name: 'Resi & Rimborsi',
						url: '#'
					},
					{
						name: 'Spedizioni',
						url: '#'
					},
					{
						name: 'Condizioni delle Carte',
						url: '#'
					},
					{
						name: 'Metodi di Pagamento',
						url: '#'
					}
				]
			},
			{
				sectionName: 'account',
				sectionLinks: [
					{
						name: 'Il mio account',
						url: '#'
					},
					{
						name: 'Ordini',
						url: '#'
					},
					{
						name: 'Wishlist',
						url: '#'
					}
				]
			},
			{
				sectionName: 'privacy',
				sectionLinks: [
					{
						name: 'Privacy Policy',
						url: '#'
					},
					{
						name: 'Termini & Condizioni',
						url: '#'
					}
				]
			}
		]
	}
}
