import { Component, Input } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
	selector: 'app-form-error-message',
	templateUrl: './form-error-message.component.html',
	styleUrl: './form-error-message.component.css'
})
export class FormErrorMessageComponent {
	@Input() control?: FormControl;
	@Input() message?: string;
	@Input() additionalClass?: string;

	public getClass = (): string => {
		return this.additionalClass ? `error-message ${this.additionalClass}` : 'error-message';
	}
}
