import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor() {
	}

	public store = (key: string, item: any) => {
		localStorage.setItem(key, JSON.stringify(item));
	}

	public remove = (key: string) => {
		localStorage.removeItem(key);
	}

	public get = <T> (key: string): T | undefined => {
		const item = localStorage.getItem(key);

		if (item) {
			return JSON.parse(item) as T;
		}

		return undefined;
	}

}
