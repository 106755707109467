import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Product } from "../../models/product";
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, of, switchMap, tap } from "rxjs";
import { ProductService } from "../../../category/services/product.service";
import { Page } from "../../models/page";

@Component({
	selector: 'app-search-box',
	templateUrl: './search-box.component.html',
	styleUrl: './search-box.component.css'
})
export class SearchBoxComponent implements AfterViewInit {
	@ViewChild('searchInput') searchInput!: ElementRef;

	public filteredProducts?: Product[];
	public searchText: string;
	public isSearching: boolean;
	public showSearches: boolean;

	constructor(private productService: ProductService, private elementRef: ElementRef) {
		this.searchText = '';
		this.isSearching = false;
		this.showSearches = false;
	}

	ngAfterViewInit() {
		this.productSearch();
	}

	public productSearch = (): void => {
		if (this.searchInput) {
			const search$ = fromEvent(this.searchInput.nativeElement, 'keyup')
				.pipe(
					map((event: any) => event.target.value),
					debounceTime(500),
					distinctUntilChanged(),
					tap(() => {
						if (this.searchText) {
							this.isSearching = true;
						} else {
							this.showSearches = false;
						}
					}),
					switchMap((text: string): Observable<Page<Product>> => text ? this.productService.searchProducts(this.searchText) : of()),
					map(products => products.content),
					tap(() => {
						this.isSearching = false;
						this.showSearches = true;
					})
				);

			search$
				.subscribe({
					next: data => {
						this.isSearching = false;
						this.filteredProducts = data;
						this.showSearches = this.searchText.length > 0;
					}
				});
		}
	}

	public reset = () => {
		this.showSearches = false;
		this.searchText = '';
	}

	public getProductLink = (product: Product): string => {
		const productName = product.name
			.toLowerCase()
			.replace(/[^a-zA-Z ]/g, '')
			.replace(/ /g, '-');
		return `/product/${ product.id }/${ productName }`;
	}

	@HostListener('document:click', ['$event'])
	public clickOutside = (event: Event) => {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showSearches = false;
		}
	}
}
