import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MenubarModule } from "primeng/menubar";
import { HeaderNavbarComponent } from './components/header-navbar/header-navbar.component';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { MobileSidenavComponent } from './components/mobile-sidenav/mobile-sidenav.component';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { WishlistMenuComponent } from "./components/wishlist-menu/wishlist-menu.component";
import { WishlistComponent } from './views/wishlist/wishlist.component';
import { CartModule } from "../cart/cart.module";


@NgModule({
	declarations: [
		HeaderComponent,
		HeaderNavbarComponent,
		TopHeaderComponent,
		MobileSidenavComponent,
		WishlistMenuComponent,
		WishlistComponent
	],
	exports: [
		HeaderComponent,
		TopHeaderComponent
	],
	imports: [
		CommonModule,
		MenubarModule,
		SharedComponentsModule,
		CartModule
	]
})
export class HeaderModule {
}
