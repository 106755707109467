<div class="container">
    <h5 class="section-title">Il mio account</h5>

    <div class="link-items">
        <a href="#" class="link-item">Ordini</a>
        <a href="#" class="link-item">Wishlist</a>
    </div>

    <p-divider/>

    <div class="link-items">
        <a href="#" class="link-item">Privacy Policy</a>
        <a href="#" class="link-item">Termini & Condizioni</a>
    </div>
</div>