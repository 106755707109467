import { Component, Input } from '@angular/core';
import { Product } from "../../models/product";
import { CartService } from "../../../services/cart.service";

@Component({
	selector: 'app-product-card',
	templateUrl: './product-card.component.html',
	styleUrl: './product-card.component.css'
})
export class ProductCardComponent {
	@Input() product?: Product;

	constructor(private cartService: CartService) {
	}

	public getSalePercentage = (product: Product): string => {
		// @ts-ignore
		let percentage = ((product.listPrice - product.salePrice) / product.listPrice) * 100;
		return `-${ percentage.toFixed(0) }%`;
	}

	public addToCart = (product: Product) => {
		this.cartService.storeProduct(product);
	}
}
