import { Component, Input } from '@angular/core';
import { Step } from "../../models/step";

@Component({
	selector: 'app-page-stepper',
	templateUrl: './page-stepper.component.html',
	styleUrl: './page-stepper.component.css'
})
export class PageStepperComponent {
	@Input() steps: Step[];

	constructor() {
		this.steps = [];
	}
}
