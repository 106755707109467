@if (products) {
	<div class="container">
		<h3 class="title">PRODOTTI CORRELATI</h3>

		<div class="products-wrapper">
			@for (product of products; track product.id) {
				<div class="product-wrapper">
					<app-product [product]="product" />
				</div>
			}
		</div>
	</div>
}