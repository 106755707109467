import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { StorageService } from "./storage.service";
import { StorageKey } from "../models/enumeration/storage-key";

@Injectable({
	providedIn: 'root'
})
export class WishlistService {

	private _wishlist = new Subject<number>();

	constructor(private storageService: StorageService) {
	}

	public notify = (productId: number) => {
		this._wishlist.next(productId);
	}

	public getDataChanges = (): Observable<number> => {
		return this._wishlist.asObservable();
	}

	public storeProduct = (productId: number) => {
		const actualProductIds: number[] = this.getSavedProductIds();
		const productIds = [...actualProductIds, productId];

		this.storageService.store(StorageKey.WISHLIST_PRODUCTS, productIds);
	}

	public removeProduct = (productId: number) => {
		const actualProductIds: number[] = this.getSavedProductIds();
		actualProductIds.splice(actualProductIds.indexOf(productId), 1);

		this.storageService.store(StorageKey.WISHLIST_PRODUCTS, actualProductIds);
	}

	public getSavedProductIds = (): number[] => {
		return this.storageService.get(StorageKey.WISHLIST_PRODUCTS) ?? [];
	}

	public isProductAlreadyAdded = (productId?: number): boolean => {
		if (!productId) {
			return false;
		}

		return this.getSavedProductIds().indexOf(productId) >= 0;
	}
}
