import { Component } from '@angular/core';

@Component({
  selector: 'app-description-tab',
  templateUrl: './description-tab.component.html',
  styleUrl: './description-tab.component.css'
})
export class DescriptionTabComponent {

}
