import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from "ngx-device-detector";
import { ProductCategoryService } from "../../../category/services/product-category.service";
import { CategoryItem } from "../../../cart/models/category-item";
import { tap } from "rxjs";
import { ProductCategory } from "../../../category/models/product-category";
import { Router } from "@angular/router";

@Component({
	selector: 'app-header-navbar',
	templateUrl: './header-navbar.component.html',
	styleUrl: './header-navbar.component.css'
})
export class HeaderNavbarComponent implements OnInit {
	public menuItems?: CategoryItem[];

	constructor(public deviceService: DeviceDetectorService, private productCategoryService: ProductCategoryService, private router: Router) {
	}

	ngOnInit() {
		this.productCategoryService.retrieveProductCategories()
			.pipe(
				tap(categories => categories.splice(0, 0, { id: 0, name: 'home', url: '/' }))
			)
			.subscribe({
				next: categories => {
					this.menuItems = this.mapCategoryData(categories, undefined);
				}
			});
	}

	private mapCategoryData = (categories: ProductCategory[], previousPath?: string): CategoryItem[] => {
		return categories.filter(category => category.showInNavigation === undefined || category.showInNavigation).map(category => {
			return {
				label: category.name,
				url: previousPath ? `category/${ previousPath }/${ category.url }` : category.url == '/' ? '/' : `category/${category.url}`,
				items: this.mapCategoryData(category.categories ?? [], category.url),
				productCategory: category,
				styleClass: previousPath ? 'sub-item' : 'main-item'
			}
		});
	}

	public navigate = (url: string) => {
		const commands = url !== '/' ? url.split('/') : ['/'];
		this.router.navigate(commands);
	}

	public canShowImage = (item: CategoryItem): boolean => {
		return this.menuItems?.find(menuItem => menuItem.productCategory.id === item.productCategory.id) !== undefined;
	}

	public canShowChevron = (item: CategoryItem): boolean => {
		return this.canShowImage(item) && item.productCategory.categories !== undefined && item.productCategory.categories.length > 0;
	}

	public isMainCategory = (item: CategoryItem): string => {
		let baseClass = 'router-link-active-'
		return baseClass + `${this.menuItems?.find(menuItem => menuItem.productCategory.id === item.productCategory.id) !== undefined ? 'main' : 'sub'}`;
	}

	public isExactUrl = (url: string): boolean => {
		return url === '/';
	}
}
