import { Component, Input, ViewChild } from '@angular/core';
import { ErrorMessage } from "../../../shared-components/models/enumeration/error-message";
import { FormControl, FormGroup } from "@angular/forms";
import { Country } from "../../models/country";
import { PaymentComponent } from "../payment/payment.component";

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrl: './payment-form.component.css'
})
export class PaymentFormComponent {
	@ViewChild(PaymentComponent) paymentComponent!: PaymentComponent;
	@Input() formGroup!: FormGroup;
	@Input() countries!: Country[];

	public isSameBillingAddress: boolean;
	public showNote: boolean;

	constructor() {
		this.isSameBillingAddress = true;
		this.showNote = false;
	}

	public getFormControl = (formControlName: string): FormControl => {
		return this.formGroup.get(formControlName) as FormControl;
	}

	public paymentProceed = (): void => {
		this.paymentComponent.pay();
	}

	protected readonly ErrorMessage = ErrorMessage;
}
