<div class="container">
	<div class="title-wrapper">
		<h4 class="title">Avvisami quando è disponibile</h4>
	</div>

	<div class="content-wrapper">
		<form class="email-form" [formGroup]="formGroup">
			<div class="email-wrapper">
				<p-floatLabel>
					<input id="notify-me" type="email" pInputText formControlName="email">
					<label for="notify-me">Inserisci la tua Mail</label>
				</p-floatLabel>

				@if (isInvalid) {
					<span class="error">Inserisci un indirizzo e-mail valido!</span>
				}
			</div>
		</form>

		<div class="button-wrapper">
			<p-button styleClass="notify-button" (click)="notifyMe()">
				AVVISAMI
			</p-button>
		</div>
	</div>
</div>