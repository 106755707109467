import { Component, Input } from '@angular/core';
import { FooterSection } from "../../models/footer-section";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
	selector: 'app-footer-section',
	templateUrl: './footer-section.component.html',
	styleUrl: './footer-section.component.css'
})
export class FooterSectionComponent {
	@Input() section?: FooterSection;

	constructor(public deviceService: DeviceDetectorService) {
	}
}
