import { Component } from '@angular/core';

@Component({
  selector: 'app-account-section',
  templateUrl: './account-section.component.html',
  styleUrl: './account-section.component.css'
})
export class AccountSectionComponent {

}
