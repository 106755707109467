import { Component, Input, OnInit } from '@angular/core';
import { EStockStatus } from "../../models/enumeration/e-stock-status";
import { StockUtils } from "../../utils/stock-utils";

@Component({
	selector: 'app-stock-label',
	templateUrl: './stock-label.component.html',
	styleUrl: './stock-label.component.css'
})
export class StockLabelComponent implements OnInit {
	@Input() productQuantity?: number;
	@Input() inStockColor: string = '#008000FF';
	@Input() fewRemainingColor: string = '#FFE600FF';
	@Input() outOfStockColor: string = '#FF0000FF';

	ngOnInit() {
		[
			{ variable: '--in-stock-color', color: this.inStockColor },
			{ variable: '--few-remaining-color', color: this.fewRemainingColor},
			{ variable: '--out-of-stock-color', color: this.outOfStockColor },
		].forEach(element =>  this.setVariableColor(element.variable, element.color));
	}

	getStockStatus = (): string | undefined => {
		if (this.productQuantity !== undefined) {
			return StockUtils.getStockTypeStatus(this.productQuantity);
		}

		return undefined;
	}

	private setVariableColor = (variable: string, color?: string): void => {
		if (color) {
			document.documentElement.style.setProperty(variable, color);
		}
	}

	protected readonly EStockStatus = EStockStatus;
}
