import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class LoadingService {

	private loading$: Subject<boolean>;

	constructor() {
		this.loading$ = new Subject<boolean>();
	}

	public isLoading = (): Observable<boolean> => {
		return this.loading$.asObservable();
	}

	public setLoading = (value: boolean) => {
		this.loading$.next(value);
	}

}
