import { Component, OnInit } from '@angular/core';
import { Featured } from "../../models/featured";
import { DeviceDetectorService } from "ngx-device-detector";
import { FeaturedService } from "../../services/featured.service";
import { LoadingService } from "../../../shared-components/services/loading.service";

@Component({
	selector: 'app-main-section',
	templateUrl: './main-section.component.html',
	styleUrl: './main-section.component.css'
})
export class MainSectionComponent implements OnInit {

	public featured?: Featured[];

	constructor(public deviceService: DeviceDetectorService,
				private featuredService: FeaturedService,
				private loadingService: LoadingService) {
	}

	ngOnInit() {
		this.retrieveFeatured();
	}

	private retrieveFeatured = () => {
		this.featuredService.retrieveFeatured()
			.subscribe({
				next: featured => {
					this.featured = featured;
					this.loadingService.setLoading(this.featured === undefined);
				}
			});
	}

}
