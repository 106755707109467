@if (featured) {
    <div class="container">
        <p-carousel
                [value]="featured"
                [numVisible]="1"
                [numScroll]="1"
                [circular]="true"
                autoplayInterval="6000">
            <ng-template let-featured pTemplate="item">
                <div class="m-2 p-3">
                    <div class="mb-3">
                        <div class="image-wrapper mx-auto">
                            <img [src]="deviceService.isMobile() ? featured.mobileImage.imageUrl : featured.desktopImage.imageUrl"
                                 [alt]="featured.name"
                                 class="border-round product-image"/>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
}