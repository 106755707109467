import { Component, Input, OnInit } from '@angular/core';
import { FeaturedProduct } from "../../models/featured-product";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
	selector: 'app-products-section',
	templateUrl: './products-section.component.html',
	styleUrl: './products-section.component.css'
})
export class ProductsSectionComponent implements OnInit {
	@Input() featuredProduct?: FeaturedProduct;
	public responsiveOptions?: any[];

	constructor(public deviceService: DeviceDetectorService) {
	}

	ngOnInit(): void {
		this.responsiveOptions = [
			{
				breakpoint: '1199px',
				numVisible: 6,
				numScroll: 6
			},
			{
				breakpoint: '991px',
				numVisible: 3,
				numScroll: 3
			},
			{
				breakpoint: '767px',
				numVisible: 1,
				numScroll: 1
			}
		];
	}

}
