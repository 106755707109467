import { Component, Input } from '@angular/core';
import { CartProduct } from "../../../cart/models/cart-product";
import { CartService } from "../../../services/cart.service";
import { Router } from "@angular/router";

@Component({
	selector: 'app-buy-button',
	templateUrl: './buy-button.component.html',
	styleUrl: './buy-button.component.css'
})
export class BuyButtonComponent {
	@Input() cartProduct?: CartProduct;
	@Input() buyDirectly?: boolean;

	constructor(private cartService: CartService, private router: Router) {
	}

	public buyNow = (): void => {
		if (this.cartProduct) {
			this.cartService.storeCartProduct(this.cartProduct);

			if (this.buyDirectly === true) {
				this.router.navigate(['/checkout']);
			}
		}
	}
}
