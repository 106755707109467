import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-payment-status',
	templateUrl: './payment-status.component.html',
	styleUrl: './payment-status.component.css'
})
export class PaymentStatusComponent implements OnInit {
	private paymentIntentId: string = '';
	public isPaymentSuccessful?: boolean;

	constructor(private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.paymentIntentId = params['payment_intent'];
			this.isPaymentSuccessful = params['redirect_status'] === 'succeeded';
		});
	}

}
