import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ProductCategoryService } from "../../services/product-category.service";
import { ProductCategory } from "../../models/product-category";
import { Title } from "@angular/platform-browser";
import { getTitle } from "../../../shared-components/utils/title-utils";
import { HttpParams } from "@angular/common/http";

@Component({
	selector: 'app-subcategory',
	templateUrl: './subcategory.component.html',
	styleUrl: './subcategory.component.css'
})
export class SubcategoryComponent implements OnInit {
	public subcategory?: ProductCategory;

	constructor(private route: ActivatedRoute, private productCategoryService: ProductCategoryService, private titleService: Title) {
	}

	ngOnInit() {
		this.route.params.subscribe({
			next: params => {
				let categoryPath = params['category_path'];
				let categorySubPath = params['subcategory_path'];

				if (categorySubPath) {
					let params = new HttpParams()
						.append('parentCategoryUrl', categoryPath)
						.append('categoryUrl', categorySubPath);
					this.productCategoryService.getCategoryFiltered(params)
						.subscribe({
							next: subcategories => {
								this.titleService.setTitle(getTitle(subcategories[0].name));
								this.subcategory = subcategories[0];
							}
						});
				}
			}
		});
	}

	public hasSubcategories = (subcategory: ProductCategory): boolean => {
		return (subcategory.categories?.flatMap(category => category.categories)?.length ?? 0) > 0;
	}

}
