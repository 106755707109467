import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	constructor(private httpClient: HttpClient) {
	}

	public createPaymentIntent = (amount: number): Observable<any> => {
		return this.httpClient.post(`${ environment.apiUrl }/payment/create-intent`, {
			amount: amount,
			currency: 'eur'
		});
	}
}
