import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { ProductInfoComponent } from "./components/product-info/product-info.component";
import { DescriptionTabComponent } from "./components/description-tab/description-tab.component";
import { CardQualityTabComponent } from "./components/card-quality-tab/card-quality-tab.component";
import { ShippingTabComponent } from "./components/shipping-tab/shipping-tab.component";
import { ProductDetailsComponent } from "./components/product-details/product-details.component";
import { ProductTabsComponent } from './components/product-tabs/product-tabs.component';
import { BreadcrumbModule } from "primeng/breadcrumb";
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { CategoryModule } from "../category/category.module";


@NgModule({
	declarations: [
		ProductDetailsComponent,
		ProductInfoComponent,
		DescriptionTabComponent,
		CardQualityTabComponent,
		ShippingTabComponent,
		ProductDetailsComponent,
		ProductTabsComponent,
		RelatedProductsComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule,
		BreadcrumbModule,
		CategoryModule
	]
})
export class ProductModule {
}
