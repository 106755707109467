import { Injectable } from '@angular/core';
import { map, Observable } from "rxjs";
import { Page } from "../../shared-components/models/page";
import { Product } from "../../shared-components/models/product";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	constructor(private httpClient: HttpClient) {
	}

	public getProductsByCategoryUrl = (categoryUrl: string, page: number, size: number, filters: HttpParams): Observable<Page<Product>> => {
		let httpParams = new HttpParams()
			.append('page', page)
			.append('size', size)
			.append('categoryUrl', categoryUrl);

		if (filters) {
			httpParams = this.mergeHttpParams(httpParams, filters);
		}

		return this.httpClient.get<Page<Product>>(`${ environment.apiUrl }/products`, { params: httpParams });
	}

	public getProductsByIds = (productIds: number[]): Observable<Product[]> => {
		const httpParams = new HttpParams()
			.append('productIds', productIds.join(','));
		return this.httpClient.get<Page<Product>>(`${ environment.apiUrl }/products`, { params: httpParams })
			.pipe(
				map(page => page.content)
			);
	}

	public getProductById = (productId: string): Observable<Product> => {
		return this.httpClient.get<Product>(`${ environment.apiUrl }/products/${ productId }`);
	}

	public searchProducts = (searchText: string, page: number = 0, size: number = 20, filters: HttpParams | undefined = undefined): Observable<Page<Product>> => {
		let httpParams = new HttpParams()
			.append('page', page)
			.append('size', size)
			.append('searchText', searchText);

		if (filters) {
			if (filters.get('availability') === 'Tutte') {
				filters = filters.delete('availability');
			}
			httpParams = this.mergeHttpParams(httpParams, filters);
		}

		return this.httpClient.get<Page<Product>>(`${ environment.apiUrl }/products`, { params: httpParams });
	}

	public getRelatedProducts = (categoryId: number, productId: number): Observable<Product[]> => {
		const httpParams = new HttpParams()
			.append('page', 0)
			.append('size', 6)
			.append('categoryId', categoryId)
			.append('excludeProductId', productId);
		return this.httpClient.get<Page<Product>>(`${ environment.apiUrl }/products`, { params: httpParams })
			.pipe(
				map(page => page.content)
			);
	}

	private mergeHttpParams = (params1: HttpParams, params2: HttpParams): HttpParams => {
		let mergedParams = new HttpParams();

		params1.keys().forEach(key => {
			const value = params1.get(key);

			if (value) {
				mergedParams = mergedParams.set(key, value);
			}
		});

		params2.keys().forEach(key => {
			const value = params2.get(key);

			if (value) {
				mergedParams = mergedParams.set(key, value);
			}
		});

		return mergedParams;
	}
}
