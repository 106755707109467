import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-updown',
	templateUrl: './updown.component.html',
	styleUrl: './updown.component.css'
})
export class UpdownComponent {
	@Input() value: number;
	@Output() valueChange: EventEmitter<number>;
	@Output() increase: EventEmitter<void>;
	@Output() decrease: EventEmitter<void>;
	@Input() maximum?: number;
	@Input() minimum?: number;

	constructor() {
		this.value = 0;
		this.valueChange = new EventEmitter();
		this.increase = new EventEmitter();
		this.decrease = new EventEmitter();
	}

	public decreaseQuantity = (): void => {
		if (this.minimum) {
			if (this.value > this.minimum) {
				this.value--;
				this.valueChange.emit(this.value);
				this.decrease.emit();
			}
		} else {
			this.value--;
			this.valueChange.emit(this.value);
			this.decrease.emit();
		}
	}

	public increaseQuantity = (): void => {
		if (this.maximum) {
			if (this.maximum > this.value) {
				this.value++;
				this.valueChange.emit(this.value);
				this.increase.emit();
			}
		} else {
			this.value++;
			this.valueChange.emit(this.value);
		}
	}

}
