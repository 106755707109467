import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FeaturedProduct } from "../models/featured-product";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class FeaturedProductService {

	constructor(private httpClient: HttpClient) {
	}

	public retrieveFeaturedProducts = (): Observable<FeaturedProduct[]> => {
		return this.httpClient.get<FeaturedProduct[]>(`${environment.apiUrl}/featured/products`);
	}

}
