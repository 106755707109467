import { AfterViewInit, Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { FormControl } from "@angular/forms";

@Directive({
	selector: '[formError]'
})
export class FormErrorDirective implements AfterViewInit {
	@Input('formError') data!: { formControl: FormControl, errorClass: string };

	constructor(private renderer: Renderer2) {
	}

	@HostListener('focusout')
	private lostFocus = () => {
		const element = document.getElementsByClassName(`${ this.data.errorClass } error-message`)[0] as HTMLElement;

		if (element) {
			this.assignClass(element);
		}
	}

	ngAfterViewInit() {
		const element = document.getElementsByClassName(`${ this.data.errorClass } error-message`)[0] as HTMLElement;

		if (element) {
			this.initError(element);
		}
	}

	private initError = (element: HTMLElement) => {
		this.renderer.setStyle(element, 'display', 'none');
	}

	private assignClass = (element: HTMLElement) => {
		if (!this.data.formControl.valid) {
			this.renderer.setStyle(element, 'display', 'block');
		} else {
			this.renderer.setStyle(element, 'display', 'none');
		}
	}

}
