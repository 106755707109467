@if (section) {
    <div [ngClass]="{'mobile-container': !deviceService.isDesktop()}">
        @if (!deviceService.isDesktop()) {
            <p-accordion class="w-full">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <h5 class="section-title">{{ section.sectionName }}</h5>
                    </ng-template>

                    <div class="section-links">
                        @for (link of section.sectionLinks; track link.name) {
                            <a href="#" class="link-item">{{ link.name }}</a>
                        }
                    </div>
                </p-accordionTab>
            </p-accordion>
        } @else {
            <h5 class="section-title">{{ section.sectionName }}</h5>
            <div class="section-links">
                @for (link of section.sectionLinks; track link.name) {
                    <a href="#" class="link-item">{{ link.name }}</a>
                }
            </div>
        }

    </div>
}