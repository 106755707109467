import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-outline-button',
	templateUrl: './outline-button.component.html',
	styleUrl: './outline-button.component.css'
})
export class OutlineButtonComponent {
	@Input() styleClass: string;
	@Output() clickEvent: EventEmitter<MouseEvent>;

	constructor() {
		this.styleClass = '';
		this.clickEvent = new EventEmitter<MouseEvent>();
	}

	public getStyleClass = (): string => {
		return `outline-button ${this.styleClass}`;
	}
}
