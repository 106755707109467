@if (subcategory && hasSubcategories(subcategory)) {
    <div class="container">
        @if (subcategory.categories) {
            @for (category of subcategory.categories; track category.id) {
                <div class="subcategory-wrapper">
                    <div class="subcategory-categories">
                        @for (subcategory of category.categories; track subcategory.id) {
                            <app-subcategory-tile [subcategory]="subcategory" class="tile"/>
                        }
                    </div>
                    <div class="image-wrapper">
                        @if (category.images && category.images[0]) {
                            <img class="subcategory-image" [src]="category.images[0].imageUrl"
                                 [alt]="category.name">
                        }
                    </div>
                </div>
            }
        } @else {
            <h1>Prodotti</h1>
        }
    </div>
} @else if (subcategory && !hasSubcategories(subcategory)) {
    <app-category [inputCategory]="subcategory"/>
}