import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Product } from "../../../shared-components/models/product";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ProductCategoryService } from "../../../category/services/product-category.service";
import { map, Observable, of } from "rxjs";
import { MenuItem } from "primeng/api";
import { capitalizeString } from "../../../utils/string-utils";
import { WishlistService } from "../../../services/wishlist.service";
import { CartProduct } from "../../../cart/models/cart-product";

@Component({
	selector: 'app-product-info',
	templateUrl: './product-info.component.html',
	styleUrl: './product-info.component.css'
})
export class ProductInfoComponent implements OnChanges {
	@Input('product') product?: Product;
	public tree$: Observable<MenuItem[]>;
	public home: MenuItem;
	public itemQuantity: number;

	constructor(
		private breakpointObserver: BreakpointObserver,
		private categoryService: ProductCategoryService,
		public wishlistService: WishlistService
	) {
		this.tree$ = of();
		this.home = {
			icon: 'pi pi-home',
			routerLink: '/'
		};
		this.itemQuantity = 1;
	}

	ngOnChanges(changes: SimpleChanges) {
		const product = (changes['product'].currentValue as Product);

		if (product) {
			this.tree$ = this.categoryService.getCategoriesTreeByProductId(product.id)
				.pipe(
					map(tree => tree.categoriesTree),
					map(categories => categories.map(category => {
							return {
								label: capitalizeString(category.name),
								routerLink: `/category/${ category.url }`,
								styleClass: 'breadcrumb-item'
							};
						})
					)
				);
		}
	}

	public getStockLabel = (product: Product) => {
		return product.stock > 0
			? product.stock + (product.stock > 1 ? ' disponibili' : ' disponibile')
			: 'Esaurito';
	}

	public addToWishlist = (e: Event, product: Product) => {
		e.stopPropagation();

		if (product) {
			if (this.wishlistService.isProductAlreadyAdded(product.id)) {
				this.wishlistService.removeProduct(product.id);
			} else {
				this.wishlistService.storeProduct(product.id);
			}

			this.wishlistService.notify(product.id);
		}
	}

	public getCartProduct = (product: Product): CartProduct => {
		return { product: product, quantity: this.itemQuantity };
	}

	public getProductMinPrice = (product: Product): number => {
		return Math.min.apply(null, product.attributes?.map(attribute => attribute.value) ?? []);
	}

	public getProductMaxPrice = (product: Product): number => {
		return Math.max.apply(null, product.attributes?.map(attribute => attribute.value) ?? []);
	}
}
