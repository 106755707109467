import { EStockStatus } from "../models/enumeration/e-stock-status";

export class StockUtils {
	public static getStockTypeStatus = (stock: number): EStockStatus => {
		if (stock === 0) {
			return EStockStatus.OUT_OF_STOCK;
		} else if (stock > 3) {
			return EStockStatus.IN_STOCK;
		}

		return EStockStatus.FEW_REMAINING;
	}
}