import { Component, Input, OnInit } from '@angular/core';
import { Product } from "../../../shared-components/models/product";
import { StockUtils } from "../../../shared-components/utils/stock-utils";
import { EStockStatus } from "../../../shared-components/models/enumeration/e-stock-status";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { WishlistService } from "../../../services/wishlist.service";
import { CartService } from "../../../services/cart.service";

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrl: './product.component.css'
})
export class ProductComponent implements OnInit {
	@Input() product?: Product;
	public isMobile: boolean = false;

	constructor(
		private breakpointObserver: BreakpointObserver,
		public wishlistService: WishlistService,
		private cartService: CartService,
	) {
	}

	ngOnInit(): void {
		this.breakpointObserver.observe(Breakpoints.XSmall)
			.subscribe({ next: result => this.isMobile = result.matches });
	}

	public getProductLink = (product: Product): string => {
		const productName = product.name
			.toLowerCase()
			.replace(/[^a-zA-Z ]/g, '')
			.replace(/ /g, '-');
		return `/product/${ product.id }/${ productName }`;
	}

	public getSalePercentage = (product: Product): string => {
		// @ts-ignore
		let percentage = ((product.listPrice - product.salePrice) / product.listPrice) * 100;
		return `-${ percentage.toFixed(0) }%`;
	}

	public addToWishlist = (e: Event, product: Product) => {
		e.stopPropagation();

		if (product) {
			if (this.wishlistService.isProductAlreadyAdded(product.id)) {
				this.wishlistService.removeProduct(product.id);
			} else {
				this.wishlistService.storeProduct(product.id);
			}

			this.wishlistService.notify(product.id);
		}
	}

	public handleClick = (product: Product) => {
		if (product && product.stock > 0) {
			this.addToCart(product);
		} else {
			
		}
	}

	private addToCart = (product: Product) => {
		if (product) {
			this.cartService.storeProduct(product);
		}
	}

	protected readonly StockUtils = StockUtils;
	protected readonly EStockStatus = EStockStatus;
}
