import { Component, Input } from '@angular/core';
import { ProductCategory } from "../../models/product-category";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: 'app-subcategory-tile',
	templateUrl: './subcategory-tile.component.html',
	styleUrl: './subcategory-tile.component.css'
})
export class SubcategoryTileComponent {
	@Input() subcategory?: ProductCategory;

	constructor(private route: ActivatedRoute, private router: Router) {
	}

	public navigateToProducts = () => {
		if (this.subcategory) {
			this.router.navigate([this.subcategory.url, 'products'], { relativeTo: this.route });
		}
	}

}
