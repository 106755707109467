import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	Renderer2,
	ViewChildren
} from '@angular/core';

@Component({
	selector: 'app-clickable-select',
	templateUrl: './clickable-select.component.html',
	styleUrl: './clickable-select.component.css'
})
export class ClickableSelectComponent implements AfterViewInit {
	@ViewChildren('itemRef') private itemRefs?: ElementRef[];
	@Input() items?: string[];
	@Input() currentValue?: string;
	@Output() onClick: EventEmitter<string>;
	public showContent: boolean;

	private activeItem?: HTMLSpanElement;

	constructor(private renderer: Renderer2) {
		this.showContent = false;
		this.onClick = new EventEmitter<string>();
	}

	ngAfterViewInit() {
		const item: HTMLSpanElement | undefined = this.itemRefs?.map(item => item.nativeElement as HTMLSpanElement)
			.find(item => item.textContent === this.currentValue);

		if (item) {
			this.itemClicked(item, '', true);
		}
	}

	public clickEvent = () => {
		this.showContent = !this.showContent;
	}

	public itemClicked = (itemRef: HTMLSpanElement, item: string, fictitious: boolean = false): void => {
		if (this.activeItem) {
			this.renderer.removeClass(this.activeItem, 'active');
		}

		this.renderer.addClass(itemRef, 'active');
		this.activeItem = itemRef;

		if (!fictitious) {
			this.onClick.emit(item);
		}
	}
}
