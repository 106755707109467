@if (rangeValues) {
	<div class="container">
		<span class="spacer"></span>
		<div class="range">
			<input type="number" [(ngModel)]="rangeValues[0]" #min>€
			<input type="number" [(ngModel)]="rangeValues[1]" #max>€
		</div>
		<div class="button-wrapper">
			<p-button styleClass="apply-filter" (click)="applyFilter.emit()">
				FILTER
			</p-button>
		</div>
	</div>
}