<div class="wrapper">
    <div class="container">
        <app-general-section/>

        <div class="sections">
            @for (section of sections; track section.sectionName) {
                <app-footer-section [section]="section"/>
            }
        </div>
    </div>
</div>