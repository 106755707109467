import { Component } from '@angular/core';

@Component({
  selector: 'app-card-quality-tab',
  templateUrl: './card-quality-tab.component.html',
  styleUrl: './card-quality-tab.component.css'
})
export class CardQualityTabComponent {

}
