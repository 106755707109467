import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-divider',
  templateUrl: './header-divider.component.html',
  styleUrl: './header-divider.component.css'
})
export class HeaderDividerComponent {

  @Input() lineWidth: string = "100%";
  @Input() lineHeight: string = "4px";
  @Input() color: string = "white";

}
