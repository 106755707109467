@if (deviceService.isDesktop()) {
    <div class="navbar-wrapper">
        <p-menubar [model]="menuItems">
            <ng-template pTemplate="item" let-item>
                <div class="p-menuitem-link menu-item" (click)="navigate(item.url)" [routerLink]="item.url"
                     [routerLinkActive]="isMainCategory(item)"
                     [routerLinkActiveOptions]="{exact: isExactUrl(item.url)}">
                    @if (canShowImage(item)) {
                        <img class="menu-item-icon" src="/assets/images/section_logo.png"
                             alt="Section logo">
                    }

                    <p class="menu-item-label">{{ item.label }}</p>

                    @if (canShowChevron(item)) {
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron">
                            <g id="bgCarrier" stroke-width="0"></g>
                            <g id="tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="iconCarrier">
                                <path d="M4 9L12 17L20 9" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                            </g>
                        </svg>
                    }
                </div>
            </ng-template>
        </p-menubar>
    </div>
}