import { MetaDefinition } from "@angular/platform-browser";

export const metaTags: MetaDefinition[] = [
	{
		name: 'description',
		content: 'Caveaugames è uno store di Pokémon e One Piece di prodotti Originali come Carte, Bustine, Box, Peluche, Figure, Accessori.'
	},
	{
		name: 'robots',
		content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
	},
	{
		name: 'og:locale',
		content: 'it_IT'
	},
	{
		name: 'og:type',
		content: 'website'
	},
	{
		name: 'og:title',
		content: 'Caveaugames'
	},
	{
		name: 'og:description',
		content: 'Caveaugames è uno store di Pokémon e One Piece di prodotti Originali come Carte, Bustine, Box, Peluche, Figure, Accessori.'
	},
	{
		name: 'og:url',
		content: 'https://www.caveaugames.com/'
	},
	{
		name: 'og:site_name',
		content: 'Caveaugames'
	},
	{
		name: 'og:image',
		content: 'https://www.caveaugames.com/wp-content/uploads/2024/02/BOX8jp-1-300x300.png'
	},
	{
		name: 'twitter:card',
		content: 'summary_large_image'
	}
]