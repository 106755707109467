@if (socialAccounts) {
    <div class="container">
        <img src="/assets/images/logo-orizzontale-solo-bianco-2.png"
             srcset="/assets/images/logo-orizzontale-solo-bianco-2.png 223w, /assets/images/logo-orizzontale-solo-bianco-2-150x33.png 150w"
             sizes="(max-width: 223px) 100vw, 223px" alt="Logo">

        <p-divider/>

        <div class="personal-info">
            <span class="item">© 2024 GEMIGNANI GIOELE</span>
            <span class="item">FIRENZE – ITALY</span>
            <span class="item">P.IVA: 07336130484</span>
            <span class="item">info&#64;caveaugames.com</span>
        </div>

        <p-divider/>

        <div class="social-buttons">
            <a class="pi pi-facebook text-3xl" [href]="socialAccounts.facebookUrl"></a>
            <a class="pi pi-instagram text-3xl" [href]="socialAccounts.instagramUrl"></a>
            <a class="pi pi-tiktok text-3xl" [href]="socialAccounts.tiktokUrl"></a>
            <a class="pi pi-whatsapp text-3xl" [href]="socialAccounts.whatsappUrl"></a>
        </div>
    </div>
}