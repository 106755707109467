import { Component, Input } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";

@Component({
	selector: 'app-wishlist-button',
	templateUrl: './wishlist-button.component.html',
	styleUrl: './wishlist-button.component.css'
})
export class WishlistButtonComponent {
	@Input() productId?: number;

	constructor(public wishlistService: WishlistService) {
	}

	public manageWishlist = () => {
		if (this.productId) {
			if (this.wishlistService.isProductAlreadyAdded(this.productId)) {
				this.wishlistService.removeProduct(this.productId);
			} else {
				this.wishlistService.storeProduct(this.productId);
			}

			this.wishlistService.notify(this.productId);
		}
	}
}
