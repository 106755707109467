<div class="container">
    @for (step of steps; track step.name; let idx = $index) {
        <a class="step-link" [routerLink]="step.link">
            <span class="step"
                  [ngClass]="{'active': step.active, 'disabled': step.link === undefined }">{{ step.name }}</span>
        </a>

        @if (idx < steps.length - 1) {
            <span class="arrow">&rarr;</span>
        }
    }
</div>