import { Component, OnInit } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";

@Component({
	selector: 'app-wishlist-menu',
	templateUrl: './wishlist-menu.component.html',
	styleUrl: './wishlist-menu.component.css'
})
export class WishlistMenuComponent implements OnInit {
	public savedProductIds: number[];

	constructor(private wishlistService: WishlistService) {
		this.savedProductIds = this.getSavedProductIds();
	}

	ngOnInit() {
		this.wishlistService.getDataChanges()
			.subscribe({
				next: _ => {
					this.savedProductIds = this.getSavedProductIds();
				}
			});
	}

	private getSavedProductIds = (): number[] => {
		return this.wishlistService.getSavedProductIds();
	}
}
