import { Component, OnInit } from '@angular/core';
import { CartProduct } from "../../models/cart-product";
import { Product } from "../../../shared-components/models/product";
import { CartService } from "../../../services/cart.service";
import { ActionType } from "../../../models/enumeration/action-type";

@Component({
	selector: 'app-cart-products',
	templateUrl: './cart-products.component.html',
	styleUrl: './cart-products.component.css'
})
export class CartProductsComponent implements OnInit {
	public cartProducts: CartProduct[];

	constructor(private cartService: CartService) {
		this.cartProducts = this.cartService.getSavedProducts();
	}

	ngOnInit(): void {
		this.cartProducts = this.cartService.getSavedProducts();
	}

	public removeProduct = (product: Product) => {
		this.cartService.removeProduct(product);
		this.cartProducts = this.cartProducts.filter(cartProduct => cartProduct.product.id !== product.id);
	}

	public editQuantity = (cartProduct: CartProduct, action: ActionType) => {
		this.cartService.editProductQuantity(cartProduct, action);
	}

	protected readonly ActionType = ActionType;
}
