@if (products && products.content) {
	<div class="container">
		@if (subcategoryUrl && products.content[0].category.images && products.content[0].category.images[0].showAsLogo !== false) {
			<div class="logo-wrapper" [ngClass]="{'mobile': isMobile}">
				<img [src]="products.content[0].category.images[0].imageUrl" [alt]="products.content[0].category.name"
					 class="product-image">
			</div>
		}

		<div class="rows-wrapper">
			<div class="row">
				<div class="paginator-wrapper">
					@if (!isMobile) {
						<span class="mx-1 items-label">Items per page: </span>
					}
					<p-dropdown [options]="options" optionLabel="label" optionValue="value" [(ngModel)]="rows"
								(ngModelChange)="pageSizeChanged($event)" styleClass="page-selector"/>
					<p-paginator [first]="first" [rows]="rows" [totalRecords]="products.page.totalElements"
								 (onPageChange)="onPageChange($event)"
								 [showCurrentPageReport]="true"
								 currentPageReportTemplate="{first} - {last} of {totalRecords}" [showPageLinks]="false"
								 [showFirstLastIcon]="false" styleClass="paginator"/>
				</div>

				<div class="order-wrapper">
					<select class="order" (change)="orderBy($event)">
						<option value="default">Ordinamento predefinito</option>
						<option value="priceAsc">Prezzo: dal più economico</option>
						<option value="priceDesc">Prezzo: dal più caro</option>
					</select>
				</div>

				<p-button styleClass="filters-button" (click)="handleFilters()">
					<i class="pi pi-sliders-h"></i>
					Filtri
				</p-button>
			</div>

			@if (showFilters) {
				<div class="row filters">
					<div class="availability">
						<app-clickable-select [items]="['Tutte', 'Disponibili', 'Prevendita']"
											  [currentValue]="currentAvailabilityFilter" (onClick)="filterBy($event)">
							<span title>disponibilità</span>
						</app-clickable-select>
					</div>

					<div class="price-wrapper">
						<app-clickable-select [currentValue]="currentAvailabilityFilter" (onClick)="filterBy($event)">
							<span title>prezzo</span>
							<div content>
								<app-price-filter [rangeValues]="priceRange" (applyFilter)="filterByPrice()"/>
							</div>
						</app-clickable-select>
					</div>
				</div>
			}
		</div>

		<div class="products-wrapper" [ngClass]="{'mobile': isMobile}">
			@for (product of products.content; track product.id) {
				<app-product [product]="product" class="product" [ngClass]="{'mobile': isMobile}"/>
			}
		</div>
	</div>
}