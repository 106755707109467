import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { injectStripe, StripePaymentElementComponent, StripeServiceInterface } from "ngx-stripe";
import { PaymentService } from "../../service/payment.service";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { CartService } from "../../../services/cart.service";
import { mergeMap } from "rxjs";
import { StripeElementsOptionsMode } from "@stripe/stripe-js/types/stripe-js/elements-group";

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrl: './payment.component.css'
})
export class PaymentComponent implements OnInit {
	@ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

	public stripe: StripeServiceInterface;
	public elementsOptions: StripeElementsOptions;
	public paymentOptions: StripePaymentElementOptions;
	public isPaying: WritableSignal<boolean>;

	constructor(private cartService: CartService, private paymentService: PaymentService) {
		this.stripe = injectStripe();
		this.elementsOptions = {
			locale: 'it',
			appearance: {
				theme: 'stripe'
			},
			currency: 'eur',
			mode: 'payment',
			paymentMethodCreation: 'manual'
		};
		this.paymentOptions = {
			layout: {
				type: 'accordion',
				defaultCollapsed: false,
				radios: false,
				spacedAccordionItems: true
			}
		};

		this.isPaying = signal(false);
	}

	ngOnInit() {
		this.cartService.getCartCost()
			.pipe(
				mergeMap(cartCosts => {
					(this.elementsOptions as StripeElementsOptionsMode).amount = cartCosts.total;
					return this.paymentService.createPaymentIntent(cartCosts.total);
				})
			)
			.subscribe({
				next: intentResponse => this.elementsOptions.clientSecret = intentResponse.clientSecret ?? '',
			});
	}

	public pay = () => {
		if (this.isPaying()) {
			return;
		}

		this.isPaying.set(true);

		fromPromise(this.paymentElement.elements.submit())
			.subscribe({
				next: _ => {
					this.stripe.confirmPayment({
						elements: this.paymentElement.elements,
						confirmParams: {
							payment_method_data: {
								billing_details: {
									name: '',
									email: '',
									address: {
										line1: 'address',
										postal_code: 'zipCode',
										city: ''
									}
								}
							},
							return_url: `http://localhost:4200/payment-status`
						},
						redirect: 'if_required',
						clientSecret: this.elementsOptions.clientSecret ?? ''
					})
						.subscribe({
							next: response => {
								this.isPaying.set(false);

								if (response.error) {
									alert(JSON.stringify({ success: false, error: response.error }));
								} else {
									if (response.paymentIntent.status === 'succeeded') {
										alert(JSON.stringify({ success: true }));
									}
								}
							}
						});
				}
			});
	}
}
