import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Country } from "../models/country";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class CountryService {

	constructor(private httpClient: HttpClient) {
	}

	public getAllCountries = (): Observable<Country[]> => {
		return this.httpClient.get<Country[]>(`${environment.apiUrl}/countries`);
	}

}
