<div class="container">
	<p-iconField iconPosition="left">
		<p-inputIcon [styleClass]="isSearching ? 'pi pi-spin pi-spinner' : 'pi pi-search'"/>
		<input class="search-box" type="text" pInputText placeholder="Search for products" #searchInput
			   [(ngModel)]="searchText"/>
	</p-iconField>

	<div class="dropdown" [hidden]="!showSearches">
		<div class="search">
			<div class="suggestions">
				@for (product of filteredProducts; track product.id) {
					<div class="suggestion" [routerLink]="getProductLink(product)" (click)="reset()">
						<img [src]="product.images[0].imageUrl" [alt]="product.name" class="image">
						<div class="product-info">
							<span class="product-name">{{ product.name }}</span>
							<div class="prices">
								<span class="list-price"
									  [ngClass]="{'discounted': product.salePrice}">{{ product.listPrice | currency:'EUR' }}</span>

								<span class="sale-price">{{ product.salePrice | currency:'EUR' }}</span>
							</div>
						</div>
					</div>
				} @empty {
					@if (searchText) {
						<span class="search-result">
							No products found
						</span>
					}
				}
			</div>

			@if (filteredProducts && filteredProducts.length > 0) {
				<div class="view-all">
					<button class="view-all-btn" routerLink="/search" [queryParams]="{ product: searchText }">
						VIEW ALL RESULTS
					</button>
				</div>
			}

		</div>
	</div>
</div>