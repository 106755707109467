import { Component, Input, OnInit } from '@angular/core';
import { ProductCategoryService } from "../../services/product-category.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductCategory } from "../../models/product-category";
import { Title } from "@angular/platform-browser";
import { getTitle } from "../../../shared-components/utils/title-utils";
import { HttpParams } from "@angular/common/http";

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrl: './category.component.css'
})
export class CategoryComponent implements OnInit {
	@Input() inputCategory?: ProductCategory;
	public category?: ProductCategory;

	constructor(private productCategoryService: ProductCategoryService, private route: ActivatedRoute,
				private router: Router, private titleService: Title) {
	}

	ngOnInit() {
		if (!this.inputCategory) {
			this.route.params.subscribe({
				next: params => {
					let categoryPath = params['category_path'];

					if (categoryPath) {
						let params = new HttpParams()
							.append('categoryUrl', categoryPath);

						this.productCategoryService.getCategoryFiltered(params)
							.subscribe({
								next: categories => {
									if (categories) {
										this.titleService.setTitle(getTitle(categories[0].name));
										this.category = categories[0];
									}
								}
							});
					}
				}
			});
		} else {
			this.category = this.inputCategory;
		}
	}

	public navigateToCategory = (categoryUrl?: string): void => {
		let commands = [];

		if (this.inputCategory) {
			commands.push('products');
		}

		if (categoryUrl) {
			commands.splice(0, 0, categoryUrl);
			this.router.navigate(commands, { relativeTo: this.route });
		}
	}

}