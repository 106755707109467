import { Component, OnInit } from '@angular/core';
import { Product } from "../../../shared-components/models/product";
import { ActivatedRoute } from "@angular/router";
import { ProductService } from "../../../category/services/product.service";

@Component({
	selector: 'app-product-details',
	templateUrl: './product-details.component.html',
	styleUrl: './product-details.component.css'
})
export class ProductDetailsComponent implements OnInit {
	public product?: Product;

	constructor(private route: ActivatedRoute, private productService: ProductService) {
	}

	ngOnInit() {
		this.route.params.subscribe({
			next: params => {
				let productId: string = params['product_id'];
				this.productService.getProductById(productId)
					.subscribe({
						next: product => this.product = product
					});
			}
		});
	}
}
