import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CouponService } from "../../services/coupon.service";
import { CartService } from "../../../services/cart.service";
import { mergeMap, of, Subscription, throwError } from "rxjs";
import { CouponForm } from "../../models/coupon-form";

@Component({
	selector: 'app-coupon',
	templateUrl: './coupon.component.html',
	styleUrl: './coupon.component.css'
})
export class CouponComponent implements OnInit, OnDestroy {
	@Output() couponChange: EventEmitter<CouponForm | undefined>;

	public couponCode: string;
	public errorMessage?: string;
	public applySubscription?: Subscription;
	public removeSubscription?: Subscription;
	public couponForm?: CouponForm;

	constructor(private couponService: CouponService, private cartService: CartService) {
		this.couponChange = new EventEmitter<CouponForm | undefined>();
		this.couponCode = '';
	}

	ngOnInit() {
		this.couponService.getAppliedCoupon()
			.subscribe({
				next: couponForm => this.couponForm = couponForm
			});
	}

	ngOnDestroy() {
		this.applySubscription?.unsubscribe();
		this.removeSubscription?.unsubscribe();
	}

	public handleCoupon = (): void => {
		if (this.couponForm) {
			this.removeCoupon();
		} else {
			this.applyCoupon();
		}
	}

	private applyCoupon = (): void => {
		this.applySubscription = this.couponService.getAppliedCoupon()
			.pipe(
				mergeMap(appliedCoupon => {
					if (appliedCoupon) {
						return throwError(() => "Coupon already applied");
					}

					return this.couponService.applyCoupon(this.couponCode, this.cartService.getSavedProducts())
				})
			)
			.subscribe({
				next: data => {
					this.couponForm = this.couponService.saveCoupon(data, this.couponCode);
					this.couponChange.emit(this.couponForm);
				},
				error: err => this.errorMessage = err.error.errors
					? err.error.errors.join("\n")
					: err.error.message
			});
	}

	private removeCoupon = (): void => {
		this.removeSubscription = this.couponService.removeCoupon()
			.subscribe();
		this.couponForm = undefined;
		this.couponChange.emit(undefined);
	}
}
