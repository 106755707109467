@if (category) {
    <div class="container">
        @if (category.categories) {
            @for (subcategory of category.categories; track category.id) {
                <div class="category-wrapper" (click)="navigateToCategory(subcategory.url)">
                    @if (subcategory.images && subcategory.images[0]) {
                        <img class="category-image" [src]="subcategory.images[0].imageUrl" [alt]="subcategory.name">
                    }
                </div>
            }
        }
    </div>
}