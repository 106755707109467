import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from "./views/cart/cart.component";
import { CartDetailsComponent } from "./components/cart-details/cart-details.component";
import { CartProductsComponent } from "./components/cart-products/cart-products.component";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { PaymentComponent } from './components/payment/payment.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { NgxStripeModule } from "ngx-stripe";
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { environment } from "../../environments/environment";
import { OrderRecapComponent } from './components/order-recap/order-recap.component';


@NgModule({
	declarations: [
		CartComponent,
		CartDetailsComponent,
		CartProductsComponent,
		PaymentComponent,
		CheckoutComponent,
		PaymentStatusComponent,
		PaymentFormComponent,
		OrderRecapComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule,
		NgxStripeModule.forRoot(environment.stripeKey)
	],
	exports: [
		CartComponent,
		CartDetailsComponent,
		CartProductsComponent
	],
})
export class CartModule {
}
