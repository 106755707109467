@if (shippingAddressForm && cartCosts) {
	<div class="details-wrapper">
		<h5 class="header-item">TOTALE CARRELLO</h5>

		<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

		@if (cartCosts.couponForm === undefined) {
			<p-inplace>
				<ng-template pTemplate="display">
					<div class="coupon-code">
						Aggiungi un codice promozionale
					</div>
				</ng-template>
				<ng-template pTemplate="content">
					<div class="coupon-container">
						<app-coupon (couponChange)="couponChanged($event)"/>
					</div>
				</ng-template>
			</p-inplace>
		} @else {
			<app-coupon (couponChange)="couponChanged($event)"/>
		}

		<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

		<div class="sub-total">
			<span class="label">Subtotale</span>
			<span class="label">{{ cartCosts.subtotal | currency:'EUR' }}</span>
		</div>


		@if (cartCosts.couponForm !== undefined) {
			<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

			<div class="discount">
				<span class="label">Sconto</span>
				<span class="label value">- {{ cartCosts.couponForm.couponResponse.discountedAmount | currency:'EUR' }}</span>
			</div>
		}

		<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

		<div class="shipping-wrapper">
			<div class="cost">
				<div class="labels">
					<span class="shipping-label label">Spedizione</span>
					<div class="company">
						<span class="shipping-company-label label">{{ cartCosts.shippingCompany }}</span>
						@if (!cartCosts.shippingCost) {
							<span class="shipping-company-label label"> (Spedizione gratuita)</span>
						}
					</div>
				</div>

				<span class="label">{{ cartCosts.shippingCost ?? 0 | currency:'EUR' }}</span>
			</div>

			<div class="shipping-address">
				<div class="actual-address">
					<span>Spedizione a </span>
					<span>{{ address }}</span>
				</div>

				<span class="change-address" (click)="showModal();">Modifica indirizzo</span>

				@if (isEditingAddress) {
					<form [formGroup]="shippingAddressForm" class="address-form">
						<p-dropdown
								formControlName="selectedCountry"
								[options]="countries"
								optionLabel="name"
								placeholder="Paese/Regione"
								filter="true"
								filterBy="name"
								styleClass="dropdown-wrapper"/>

						<p-floatLabel>
							<input id="zip-code" pInputText formControlName="selectedZipCode"/>
							<label class="input-label" for="zip-code">CAP</label>
						</p-floatLabel>

						<p-floatLabel>
							<input id="city" pInputText formControlName="selectedCity"/>
							<label class="input-label" for="city">Città</label>
						</p-floatLabel>

						<p-dropdown
								formControlName="selectedProvince"
								[options]="getFormControlValue('selectedCountry').provinces"
								optionLabel="name"
								placeholder="Provincia"
								filter="true"
								filterBy="name"
								styleClass="dropdown-wrapper"/>

						<div class="update">
							<p-button styleClass="update-button" (click)="updateAddress()">
								AGGIORNA
							</p-button>
						</div>
					</form>
				}
			</div>
		</div>

		<app-header-divider class="divider" color="rgba(255, 255, 255, 0.5)" lineHeight="1px"/>

		<div class="total">
			<span class="label">Totale</span>
			<span class="label cost">{{ cartCosts.total | currency:'EUR' }}</span>
		</div>

		<div class="payment-methods">
			<p-button styleClass="proceed-button" routerLink="/checkout">
				Concludi il pagamento
			</p-button>
		</div>

	</div>
}