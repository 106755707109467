import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryComponent } from './components/category/category.component';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { SubcategoryComponent } from './components/subcategory/subcategory.component';
import { SubcategoryTileComponent } from './components/subcategory-tile/subcategory-tile.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/product/product.component';
import { PaginatorModule } from "primeng/paginator";


@NgModule({
	declarations: [
		CategoryComponent,
		SubcategoryComponent,
		SubcategoryTileComponent,
		ProductsComponent,
		ProductComponent
	],
	imports: [
		CommonModule,
		SharedComponentsModule,
		PaginatorModule
	],
	exports: [
		CategoryComponent,
		ProductComponent
	]
})
export class CategoryModule {
}
