@if (product) {
    <div class="product-wrapper" [ngClass]="{'mobile': isMobile}">
        <div class="image-wrapper relative" [routerLink]="getProductLink(product)" [ngClass]="{'mobile': isMobile}">
            @if (product.salePrice) {
                <p-tag [value]="getSalePercentage(product)" class="absolute sale-tag"/>
            }

            @if (product.images && product.images[0]) {
                <img class="product-image" [src]="product.images[0].imageUrl" [alt]="product.name">
            } @else {
                <h3>No img found</h3>
            }

            <p-button [icon]="'pi pi-heart' + (wishlistService.isProductAlreadyAdded(product.id) ? '-fill' : '')" class="absolute add-to-wishlist" styleClass="wishlist-icon"
                      (click)="addToWishlist($event, product)"/>
        </div>
        <div class="product-name-wrapper">
            <a [routerLink]="getProductLink(product)" class="product-name">{{ product.name }}</a>
        </div>

        <app-stock-label [productQuantity]="product.stock" inStockColor="lime"/>

        <div class="price-wrapper">
            <span class="list-price"
                  [ngClass]="{'on-sale': product.salePrice}">{{ product.listPrice | currency:'EUR' }}</span>

            @if (product.salePrice) {
                <span class="sale-price">{{ product.salePrice | currency:'EUR' }}</span>
            }
        </div>

        <div class="buttons-wrapper">
            <p-button class="cart-button" styleClass="add-to-cart" (click)="handleClick(product)">
                {{ StockUtils.getStockTypeStatus(product.stock) === EStockStatus.OUT_OF_STOCK ? 'AVVISAMI' : 'AGGIUNGI' }}
            </p-button>
        </div>
    </div>
}