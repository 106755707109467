import { Component, Input, OnInit } from '@angular/core';
import { Product } from "../../../shared-components/models/product";
import { ProductService } from "../../../category/services/product.service";

@Component({
	selector: 'app-related-products',
	templateUrl: './related-products.component.html',
	styleUrl: './related-products.component.css'
})
export class RelatedProductsComponent implements OnInit {
	@Input() categoryId?: number;
	@Input() productId?: number;

	public products?: Product[];

	constructor(private productService: ProductService) {
	}

	ngOnInit() {
		if (this.categoryId && this.productId) {
			this.productService.getRelatedProducts(this.categoryId, this.productId)
				.subscribe({
					next: products => this.products = products
				});
		}
	}

}
