@if (product) {
	<div class="container">
		<div class="product-wrapper">
			<app-product-info [product]="product"/>
		</div>

		<div class="tabs-wrapper">
			<app-product-tabs [product]="product"/>
		</div>

		<div class="related-products">
			<app-related-products [categoryId]="product.category.id" [productId]="product.id"/>
		</div>
	</div>
}