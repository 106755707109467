<div class="select-container">
	<div class="select-wrapper" (click)="clickEvent()">
		<ng-content select="[title]"></ng-content>
		<i class="pi" [ngClass]="{ 'pi-chevron-down': !showContent, 'pi-chevron-up': showContent }"></i>
	</div>

	<div class="content" [hidden]="!showContent">
		@if (items) {
			@for (item of items; track item) {
				<span [hidden]="!showContent" class="item"
					  (click)="itemClicked(itemRef, item)" #itemRef>{{ item }}</span>
			}
		} @else {
			@if (showContent) {
				<ng-content select="[content]"></ng-content>
			}
		}
	</div>
</div>