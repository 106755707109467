import { Component, OnInit } from '@angular/core';
import { WishlistService } from "../../../services/wishlist.service";
import { Product } from "../../../shared-components/models/product";
import { ProductService } from "../../../category/services/product.service";

@Component({
	selector: 'app-wishlist',
	templateUrl: './wishlist.component.html',
	styleUrl: './wishlist.component.css'
})
export class WishlistComponent implements OnInit {
	public products: Product[];

	constructor(private wishlistService: WishlistService, private productService: ProductService) {
		this.products = [];
	}

	ngOnInit(): void {
		this.retrieveProducts();
		this.wishlistService.getDataChanges()
			.subscribe({
				next: _ => {
					this.retrieveProducts();
				}
			});
	}

	private retrieveProducts = () => {
		const productIds = this.wishlistService.getSavedProductIds();
		this.productService.getProductsByIds(productIds)
			.subscribe({ next: products => this.products = products });
	}
}
