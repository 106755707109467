import { Injectable } from '@angular/core';
import { ProductCategory } from "../models/product-category";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { CategoriesTree } from "../models/categories-tree";

@Injectable({
	providedIn: 'root'
})
export class ProductCategoryService {

	constructor(private httpClient: HttpClient) {
	}

	public retrieveProductCategories = (): Observable<ProductCategory[]> => {
		return this.httpClient.get<ProductCategory[]>(`${ environment.apiUrl }/categories`);
	}

	public getCategoryFiltered = (params: HttpParams): Observable<ProductCategory[]> => {
		return this.httpClient.get<ProductCategory[]>(`${ environment.apiUrl }/categories/filtered`, { params: params });
	}

	public getCategoriesTreeByProductId = (productId: number): Observable<CategoriesTree> => {
		return this.httpClient.get<CategoriesTree>(`${ environment.apiUrl }/categories/tree/${ productId }`);
	}
}
