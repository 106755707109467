import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { DividerModule } from "primeng/divider";
import { GeneralSectionComponent } from './components/general-section/general-section.component';
import { FooterSectionComponent } from './components/footer-section/footer-section.component';
import { AccountSectionComponent } from './components/account-section/account-section.component';
import { AccordionModule } from "primeng/accordion";


@NgModule({
	declarations: [
		FooterComponent,
		GeneralSectionComponent,
		FooterSectionComponent,
  AccountSectionComponent
	],
	imports: [
		CommonModule,
		DividerModule,
		AccordionModule
	],
	exports: [
		FooterComponent
	]
})
export class FooterModule {
}
