import { Component } from '@angular/core';

@Component({
  selector: 'app-shipping-tab',
  templateUrl: './shipping-tab.component.html',
  styleUrl: './shipping-tab.component.css'
})
export class ShippingTabComponent {

}
