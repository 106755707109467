import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './view/homepage/homepage.component';
import { MainSectionComponent } from './components/main-section/main-section.component';
import { CarouselModule } from "primeng/carousel";
import { ProductsSectionComponent } from './components/products-section/products-section.component';
import { ButtonModule } from "primeng/button";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { TooltipModule } from "primeng/tooltip";
import { ProductsSectionsComponent } from './components/products-sections/products-sections.component';
import { ScrollTopModule } from "primeng/scrolltop";


@NgModule({
	declarations: [
		HomepageComponent,
		MainSectionComponent,
		ProductsSectionComponent,
		ProductsSectionsComponent,
	],
	imports: [
		CommonModule,
		CarouselModule,
		ButtonModule,
		SharedComponentsModule,
		TooltipModule,
		ScrollTopModule
	]
})
export class HomepageModule {
}
