import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-price-filter',
	templateUrl: './price-filter.component.html',
	styleUrl: './price-filter.component.css'
})
export class PriceFilterComponent implements AfterViewInit {
	@ViewChild('min') minRef?: ElementRef;
	@ViewChild('max') maxRef?: ElementRef;
	@Input() rangeValues?: number[];
	@Output() applyFilter: EventEmitter<void>;

	constructor() {
		this.applyFilter = new EventEmitter<void>();
	}

	ngAfterViewInit(): void {
		if (this.minRef) {
			this.minRef.nativeElement.addEventListener('change', () => this.validateMax());
			this.minRef.nativeElement.addEventListener('input', () => this.validateMax());
			this.minRef.nativeElement.addEventListener('keyup', () => this.validateMax());
			this.minRef.nativeElement.addEventListener('paste', () => this.validateMax());
		}

		if (this.maxRef) {
			this.maxRef.nativeElement.addEventListener('change', () => this.validateMin());
			this.maxRef.nativeElement.addEventListener('input', () => this.validateMin());
			this.maxRef.nativeElement.addEventListener('keyup', () => this.validateMin());
			this.maxRef.nativeElement.addEventListener('paste', () => this.validateMin());
		}
	}

	private validateMax = () => {
		if (this.rangeValues && this.rangeValues[0] && this.rangeValues[1]) {
			if (this.rangeValues[0] > this.rangeValues[1]) {
				this.rangeValues[0] = this.rangeValues[1];
			}
		}
	}

	private validateMin = () => {
		if (this.rangeValues && this.rangeValues[0] && this.rangeValues[1]) {
			if (this.rangeValues[1] < this.rangeValues[0]) {
				this.rangeValues[1] = this.rangeValues[0];
			}
		}
	}
}
