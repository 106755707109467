import { Component } from '@angular/core';
import { Step } from "../../../shared-components/models/step";

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrl: './cart.component.css'
})
export class CartComponent {
	public steps: Step[];

	constructor() {
		this.steps = [
			{ name: 'shopping cart', link: '/cart', active: true },
			{ name: 'checkout', link: '/checkout' },
			{ name: 'order complete' },
		];
	}

}
