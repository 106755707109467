@if (featuredProduct) {
    <div class="container">
        <span class="section-title">{{ featuredProduct.name }}</span>
        <p-carousel
                class="carousel"
                [value]="featuredProduct.products"
                [numVisible]="5"
                [numScroll]="5"
                [circular]="false"
                [responsiveOptions]="responsiveOptions">
            <!--            [orientation]="!deviceService.isDesktop() ? 'vertical' : 'horizontal'"-->
            <!--            verticalViewPortHeight="340px">-->
            <ng-template let-product pTemplate="item">
                <app-product-card [product]="product"></app-product-card>
            </ng-template>
        </p-carousel>
    </div>
} @else {
    <div class="loading">
        <img src="/assets/images/loading-spinner.gif" alt="">
    </div>
}